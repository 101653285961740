const imgRegex = /<img[^>]*src="([^"]+)"/g

export default content => {
  const matches = content.match(imgRegex)
  return matches
    ? matches.reduce((prev, match) => {
        const newString = prev
          // .replace(match, match.toLowerCase())
          .replace('"/Images/', '"/ne-images/')
        return newString
      }, content)
    : content
}
