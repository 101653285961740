import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Page from '../components/page'

export default () => {
  const { pathname } = useLocation()
  return (
    <Page>
      <main className="container flex-grow max-w-5xl mx-auto">
        <h1 className="text-2xl font-bold">Page {pathname} does not exist</h1>
        <Link to="/" className="text-blue-700 hover:text-blue-500">
          Go back home
        </Link>
      </main>
    </Page>
  )
}
