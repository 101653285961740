import React, { lazy, Suspense } from 'react'
import { importMDX } from 'mdx.macro'

import HashLinkObserver from "react-hash-link";
import lowercaseImgSrc from '../lib/lowercaseImgSrc'
import ScrollTo from "../lib/scrollTo";

const TermsOfUse = lazy(() => importMDX('../content/termsOfUse.mdx'))

export default ({ id, policy }) => {
  const { content } = policy
  const newContent = lowercaseImgSrc(content)
  return (
    <article>
      <HashLinkObserver smoothScroll={false}/>
      {id === 'termsofuse' ? (
        <div className="text-sm article-content markdown">
          <Suspense fallback={<div>Loading...</div>}>
            <TermsOfUse />
            <ScrollTo id="#Disclaimer" />
          </Suspense>
        </div>
      ) : (
        <div className="text-sm article-content" dangerouslySetInnerHTML={{ __html: newContent }} />
      )}
    </article>
  )
}
