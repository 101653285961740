import React from 'react'

import lowercaseImgSrc from '../lib/lowercaseImgSrc'

export default ({ article }) => {
  const { title, teaser, author, content } = article
  const newContent = lowercaseImgSrc(content)
  return (
    <article className="space-y-2">
      <h1 className="text-2xl font-bold">{title}</h1>
      <h2>{teaser}</h2>
      <h3 className="text-sm italic text-gray-600">by {author}</h3>
      <div className="text-sm article-content" dangerouslySetInnerHTML={{ __html: newContent }} />
    </article>
  )
}
