import { host, query } from './host'
import articles from './articles'

export default {
  main: [
    {
      id: 'Shop By Category',
      columns: 6,
      border: true,
      viewAll: {
        text: 'categories',
        url: '/',
      },
      items: [
        {
          text: 'Crazy Deals',
          url: `${host}/sale-items${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Crazy Deals',
          img: 'https://cdn2.pipingrock.com/mobile/img/hp-transparent.png?v=2020111101',
          crazy: true,
          mobile: true,
        },
        {
          text: 'Supplements',
          url: `${host}/supplements-mc${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Supplements',
          img: 'https://cdn2.pipingrock.com/website/img/featured/supplements@2x.png?v=2020072901',
        },
        {
          text: 'Essential Oils',
          url: `${host}/essential-oils-mc${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Essential Oils',
          img: 'https://cdn2.pipingrock.com/website/img/featured/essential-oils@2x.png?v=2020072901',
        },
        {
          text: 'Crazy Deals Ends Soon!',
          url: `${host}/sale-items${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Crazy Deals Ends Soon!',
          img: '/ne-images/home/Crazy Deals.png',
          lg: true,
          crazy: true,
        },
        {
          text: 'Sports',
          url: `${host}/sports-fitness-mc${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Sports',
          img: 'https://cdn2.pipingrock.com/website/img/featured/sports@2x.png?v=2020072901',
        },
        {
          text: 'Joint',
          url: `${host}/joint-support-supplements${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Joint',
          img: 'https://cdn2.pipingrock.com/website/img/featured/joint@2x.png?v=2020072901',
        },
        {
          text: 'Herbs',
          url: `${host}/herbal-supplements-mc${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Herbs',
          img: 'https://cdn2.pipingrock.com/website/img/featured/herbs@2x.png?v=2020072901',
        },
        {
          text: 'Skin Care',
          url: `${host}/skin-care${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Skin Care',
          img: 'https://cdn2.pipingrock.com/website/img/featured/skin-care@2x.png?v=2020072901',
        },
        {
          text: 'Weight Support',
          url: `${host}/weight-loss-support${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Weight Support',
          img:
            'https://cdn2.pipingrock.com/website/img/featured/WeightSupport_788x754px.png?v=2020072901',
          lg: true,
        },
        {
          text: 'Nuts & Seeds',
          url: `${host}/nuts-seeds${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Nuts & Seeds',
          img: 'https://cdn2.pipingrock.com/website/img/featured/nuts-seeds@2x.png?v=2020072901',
        },
        {
          text: 'Immune Support',
          url: `${host}/immune-support${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Immune Support',
          img: 'https://cdn2.pipingrock.com/website/img/featured/immune.png?v=2020072901',
        },
        {
          text: 'Probiotics',
          url: `${host}/acidophilus-probiotics${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Probiotics',
          img: 'https://cdn2.pipingrock.com/website/img/featured/probiotics@2x.png?v=2020072901',
        },
        {
          text: 'Antioxidants',
          url: `${host}/anti-aging-antioxidants${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Antioxidants',
          img: 'https://cdn2.pipingrock.com/website/img/featured/antioxidant@2x.png?v=2020072901',
        },
        {
          text: 'Aromatherapy',
          url: `${host}/aromatherapy${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Aromatherapy',
          img: 'https://cdn2.pipingrock.com/website/img/featured/aroma@2x.png?v=2020072901',
        },
        {
          text: 'Heart Health',
          url: `${host}/heart-health${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Heart Health',
          img: 'https://cdn2.pipingrock.com/website/img/featured/heart@2x.png?v=2020072901',
        },
        {
          text: "Men's Vitamins",
          url: `${host}/men-s-vitamins${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: "Men's Vitamins",
          img: 'https://cdn2.pipingrock.com/website/img/featured/mens-vitamins.png?v=2020072901',
        },
        {
          text: "Women's Vitamins",
          url: `${host}/women-s-vitamins${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: "Women's Vitamins",
          img: 'https://cdn2.pipingrock.com/website/img/featured/women-s-vitamins.png?v=2020072901',
        },
        {
          text: 'Fragrance Oils',
          url: `${host}/fragrance${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Fragrance Oils',
          img: 'https://cdn2.pipingrock.com/website/img/featured/fragrance-oil.png?v=2020072901',
        },
        {
          text: 'CoQ10',
          url: `${host}/coq10${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'CoQ10',
          img: 'https://cdn2.pipingrock.com/website/img/featured/coq10.png?v=2020072901',
        },
        {
          text: 'Weight Support',
          url: `${host}/weight-loss-support${query}`,
          category: 'Home Page Grid',
          action: 'Click',
          label: 'Weight Support',
          img: '/ne-images/weight-support.png',
          mobile: true,
        },
      ],
    },
    {
      id: 'Featured Articles',
      columns: 4,
      article: true,
      border: false,
      items: articles,
    },
  ],
  aside: [
    {
      text: 'Home',
      url: `/`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Home',
      top: true,
      bold: true,
      mobile: true,
      desktop: false,
    },
    {
      text: 'Shop All Categories',
      url: `${host}/categories${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Shop All Categories',
      top: true,
      bold: true,
      mobile: true,
    },
    {
      text: 'Shop All Products',
      url: `${host}/products${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Shop All Products',
      top: true,
      bold: true,
      mobile: true,
    },
    {
      text: 'Shop All Brands',
      url: `${host}/brands${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Shop All Brands',
      top: true,
      bold: true,
      mobile: true,
    },
    {
      text: 'Crazy Deals',
      url: `${host}/sale-items${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Crazy Deals',
      bold: true,
      mobile: true,
      desktop: false,
    },
    {
      text: 'Sale Items',
      url: `${host}/sale-items${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Sale Items',
      sale: true,
      bold: true,
    },
    {
      text: 'Supplements',
      url: `${host}/supplements-mc${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Supplements',
      mobile: true,
      desktop: false,
    },
    {
      text: 'Essential Oils',
      url: `${host}/essential-oils-mc${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Essential Oils',
      bold: true,
      mobile: true,
    },
    {
      text: 'Fragrance Oils',
      url: `${host}/fragrance${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Fragrance Oils',
    },
    {
      text: 'Aromatherapy',
      url: `${host}/aromatherapy${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Aromatherapy',
    },
    {
      text: 'Immune Support',
      url: `${host}/immune-support${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Immune Support',
      bold: true,
    },
    {
      text: 'Supplements',
      url: `${host}/supplements-mc${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Supplements',
    },
    {
      text: 'CoQ10',
      url: `${host}/coq10${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'CoQ10',
    },
    {
      text: 'Elderberry',
      url: `${host}/elder-berries${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Elderberry',
      bold: true,
    },
    {
      text: 'Vitamin C',
      url: `${host}/vitamin-c${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Vitamin C',
    },
    {
      text: 'Vitamin D',
      url: `${host}/vitamin-d${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Vitamin D',
      bold: true,
    },
    {
      text: 'Probiotics',
      url: `${host}/acidophilus-probiotics${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Probiotics',
    },
    {
      text: 'Melatonin',
      url: `${host}/melatonin${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Melatonin',
      bold: true,
    },
    {
      text: "Men's Vitamins",
      url: `${host}/men-s-vitamins${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: "Men's Vitamins",
      bold: true,
    },
    {
      text: "Women's Vitamins",
      url: `${host}/women-s-vitamins${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: "Women's Vitamins",
      bold: true,
    },
    {
      text: 'Skin Care',
      url: `${host}/skin-care${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Skin Care',
    },
    {
      text: 'Zinc',
      url: `${host}/zinc${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Zinc',
      bold: true,
    },
    {
      text: 'Beauty & Personal Care',
      url: `${host}/beauty-personal-care-mc${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Beauty & Personal Care',
    },
    {
      text: 'Personal Care',
      url: `${host}/beauty-personal-care-mc${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Personal Care',
      mobile: true,
      desktop: false,
    },
    {
      text: 'Hair, Skin, Nails',
      url: `${host}/hair-skin-nails${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Hair, Skin, Nails',
      mobile: true,
      desktop: false,
    },
    {
      text: 'Multivitamins',
      url: `${host}/multivitamins${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Multivitamins',
      mobile: true,
      desktop: false,
    },
    {
      text: 'Essential Oil Roll-On',
      url: `${host}/essential-oil-roll-on${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Essential Oil Roll-On',
    },
    {
      text: 'Collagen',
      url: `${host}/collagen${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Collagen',
      bold: true,
    },
    {
      text: 'Weight Support',
      url: `${host}/weight-loss-support${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Weight Support',
    },
    {
      text: 'Keto Diet Products',
      url: `${host}/keto${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Keto Diet Products',
      bold: true,
    },
    {
      text: 'Nuts & Seeds',
      url: `${host}/nuts-seeds${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Nuts & Seeds',
      bold: true,
    },
    {
      text: 'Tea',
      url: `${host}/herbal-teas${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Tea',
    },
    {
      text: 'Organic Products',
      url: `${host}/organic-products${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Organic Products',
      bold: true,
    },
    {
      text: 'Herbal Supplements',
      url: `${host}/herbal-supplements-mc${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Herbal Supplements',
      bold: true,
    },
    {
      text: 'Herbs',
      url: `${host}/herbal-supplements-mc${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Herbs',
      bold: true,
      mobile: true,
      desktop: false,
    },
    {
      text: 'Aromatherapy',
      url: `${host}/aromatherapy${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Aromatherapy',
      mobile: true,
      desktop: false,
    },
    {
      text: 'Antioxidants',
      url: `${host}/anti-aging-antioxidants${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Antioxidants',
      mobile: true,
      desktop: false,
    },
    {
      text: 'Bulk Herbs',
      url: `${host}/bulk-herbs${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Bulk Herbs',
    },
    {
      text: 'Liquid Extracts',
      url: `${host}/liquid-extracts${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Liquid Extracts',
    },
    {
      text: 'Joint Support',
      url: `${host}/joint-support-supplements${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Joint Support',
    },
    {
      text: 'Pet Products',
      url: `${host}/pet-products${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Pet Products',
    },
    {
      text: 'Sports & Fitness',
      url: `${host}/sports-fitness-supplements-mc${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Sports & Fitness',
      bold: true,
    },
    {
      text: 'Sports',
      url: `${host}/sports-fitness-supplements-mc${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Sports',
      bold: true,
      mobile: true,
      desktop: false,
    },
    {
      text: 'Spices',
      url: `${host}/spices${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Spices',
    },
    {
      text: 'Turmeric',
      url: `${host}/curcumin-turmeric${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Turmeric',
    },
    {
      text: 'Homeopathics',
      url: `${host}/homeopathics${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Homeopathics',
    },
    {
      text: 'Compare and Save',
      url: `${host}/comparesave${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Compare and Save',
    },
    {
      text: 'Vegan Products',
      url: `${host}/vegan-products${query}`,
      category: 'Left Navigation',
      action: 'Click',
      label: 'Vegan Products',
      bold: true,
    },
  ],
  slider: {
    url: `${host}/sale-items${query}`,
    img: '/ne-images/home/NE_ENG_large.svg',
  },
}
