import aboutus from './policies/1534.json'
import shippingordering from './policies/1540.json'
import faqs from './policies/1537.json'
import faqsmyaccount from './policies/1538.json'
import guaranteereturns from './policies/1539.json'
import internationalorders from './policies/1536.json'
import termsofuse from './policies/1541.json'
import privacy from './policies/1535.json'
import nongmopledge from './policies/3155.json'

export default [
  { id: 'aboutus', articleid: 1534, title: 'About Us', policy: aboutus },
  { id: 'contactus', articleid: 0, title: 'Contact Us' },
  {
    id: 'shippingordering',
    articleid: 1540,
    title: 'Shipping / Ordering Policies',
    policy: shippingordering,
  },
  { id: 'faqs', articleid: 1537, title: 'FAQs', policy: faqs },
  { id: 'faqsmyaccount', articleid: 1538, title: 'FAQs: My Account', policy: faqsmyaccount },
  {
    id: 'guaranteereturns',
    articleid: 1539,
    title: 'Returns & Guarantees',
    policy: guaranteereturns,
  },
  {
    id: 'internationalorders',
    articleid: 1536,
    title: 'International Orders',
    policy: internationalorders,
  },
  { id: 'termsofuse', articleid: 1541, title: 'Terms of Use', policy: termsofuse },
  { id: 'privacy', articleid: 1535, title: 'Privacy Policy', policy: privacy },
  { id: 'nongmopledge', articleid: 3155, title: 'Non-GMO Pledge', policy: nongmopledge },
]
