import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Page from '../components/page'
import Article from '../components/article'
import articles from '../data/articles'

const fetchArticle = id => articles.find(a => a.id === parseInt(id, 10))

export default () => {
  const { search } = useLocation()
  const query = search
    .replace('?', '')
    .split('&')
    .reduce((prev, entry) => {
      const [key, value] = entry.split('=')
      prev[key] = value
      return prev
    }, {})
  const { id, articleid } = query
  const articleData = id ? fetchArticle(id) : articleid ? fetchArticle(articleid) : {}
  const { article } = articleData || {}
  if (!article)
    return (
      <Page>
        <main className="container flex-grow max-w-5xl mx-auto">
          <h1 className="text-2xl font-bold">Article does not exist</h1>
          <Link to="/" className="text-blue-700 hover:text-blue-500">
            Go back home
          </Link>
        </main>
      </Page>
    )
  return (
    <Page title={article.title}>
      <div className="container flex max-w-2xl px-4 mx-auto space-x-4 md:p-0">
        <main>
          <Article article={article} />
        </main>
      </div>
    </Page>
  )
}
