import React from 'react'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'

import Page from '../components/page'
import LeftNav from '../components/leftNav'
import useTimer from '../lib/useTimer'
import home from '../data/home'
import useSource from '../lib/useSource'

const { main, slider } = home

export default () => {
  const { data } = useSource()
  const sliderDesktop = data?.images?.large_desktop
  const sliderMobile = data?.images?.large_mobile
  const description  = data?.description
  const { expirationDate, hide_expiration } = data || {}
  const showExpiration = hide_expiration === '0'
  const date = showExpiration
    ? expirationDate
      ? format(parseISO(expirationDate), 'MMMM d, y')
      : null
    : null
  const { hours, minutes, seconds } = useTimer()
  return (
    <Page>
      <div className="container flex justify-center mt-4 space-x-0 max-w-7xl md:px-4 lg:space-x-4 md:mx-auto xl:px-0">
        <LeftNav />
        <main className="flex flex-col space-y-4 lg:w-5/6">
          {sliderDesktop && sliderMobile && (
            <a href={slider.url} className="relative">
              <img className="hidden w-full md:block" alt={description} src={sliderDesktop} />
              <img className="block w-full md:hidden" alt={description} src={sliderMobile} />
              {showExpiration ? (
                <div className="absolute bottom-0 flex items-center justify-center w-full space-x-4 text-gray-100 md:text-xl">
                  <span className="font-bold uppercase">Hurry!</span>
                  <span className="italic">Expires {date}</span>
                </div>
              ) : null}
            </a>
          )}
          {main.map(({ id, article, columns, border, viewAll, items }) => (
            <div key={id} className="space-y-2 md:space-y-4">
              {article && (
                <h1 className="inline-flex items-center justify-center w-full space-x-3 text-xl text-gray-600 md:justify-start">
                  <span className="font-semibold">{id}</span>

                  {viewAll && (
                    <Link className="text-xs text-blue-700 hover:text-blue-500" to={viewAll.url}>
                      view all {viewAll.text}
                    </Link>
                  )}
                </h1>
              )}
              <ul
                className={`px-4 md:px-0 grid grid-cols-2 border-collapse gap-4 ${
                  columns === 5
                    ? 'md:grid-cols-5'
                    : 'md:grid-cols-4 xl:grid-cols-6 grid-flow-row-dense'
                }`}
              >
                {items.map(({ text, url, category, action, label, img, lg, crazy, mobile }) => (
                  <li
                    key={`${text}-${mobile ? 'mobile' : ''}`}
                    className={`${
                      lg
                        ? 'hidden md:block md:col-span-2 md:row-span-2 md:col-start-3 xl:col-start-5'
                        : ''
                    } ${article ? 'group' : ''} ${mobile ? 'block md:hidden' : ''}`}
                  >
                    <a
                      className={`block ${
                        border ? 'border border-gray-300 hover:border-orange-600' : ''
                      } ${crazy ? 'flex flex-col h-full' : ''}`}
                      href={url}
                      data-category={category}
                      data-action={action}
                      data-label={label}
                    >
                      {img && (
                        <div className="relative flex items-center justify-center">
                          <img
                            className={`block ${article ? 'mx-auto md:mx-0' : 'mx-auto'}`}
                            src={img}
                            alt={text}
                          />
                          {crazy && mobile && (
                            <div className="absolute w-full font-bold text-center">
                              <div className="text-2xl text-brand-red">Sale!</div>
                              <div className="uppercase">Ends In</div>
                              <div className="flex justify-center text-2xl">
                                {hours} : {minutes} : {seconds}
                              </div>
                              <div className="flex text-sm justify-evenly">
                                <span>HRS</span>
                                <span>MIN</span>
                                <span>SEC</span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      <div
                        className={`font-bold group-hover:text-blue-500 ${
                          article ? 'pt-2 text-center md:text-left' : 'text-center py-2'
                        } ${crazy ? 'bg-brand-red' : border ? 'bg-gray-400' : ''} ${
                          crazy ? 'text-gray-100' : 'text-blue-700'
                        } ${crazy ? 'flex-grow flex items-center justify-center' : ''}`}
                      >
                        {crazy && lg ? (
                          <div className="space-y-0">
                            {/* <div className="text-lg uppercase">Ends In</div> */}
                            <div className="flex font-normal divide-x divide-crazy-border">
                              <div className="px-4">
                                <div className="text-4xl leading-none">{hours}</div>
                                <div className="text-sm font-bold">HRS</div>
                              </div>
                              <div className="px-4">
                                <div className="text-4xl leading-none">{minutes}</div>
                                <div className="text-sm font-bold">MIN</div>
                              </div>
                              <div className="px-4">
                                <div className="text-4xl leading-none">{seconds}</div>
                                <div className="text-sm font-bold">SEC</div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          text
                        )}
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </main>
      </div>
    </Page>
  )
}
