import React from 'react'
import { useParams, useLocation } from 'react-router-dom'

import Page from '../components/page'
import Breadcrumbs from '../components/breadcrumbs'
import Order from '../components/order'
import LeftNav from '../components/accountLeftNav'
import fetch from '../lib/fetchJson'
import convertOrder from '../lib/convertOrder'

export default () => {
  const { id } = useParams()
  const { pathname } = useLocation()
  const { data } = fetch(`https://hist-api.systemne.com/invoices/${id}`)
  const order = data ? convertOrder(data) : undefined
  return (
    <Page title="My Account">
      <div className="container flex flex-wrap px-2 mx-auto md:flex-no-wrap md:space-x-4 nex:max-w-5xl md:px-0">
        <aside className="w-full md:w-auto">
          <LeftNav current={pathname} />
        </aside>
        <main className="flex-grow space-y-2">
          <Breadcrumbs
            className="hidden md:flex"
            current={`/orders/${id}`}
            breadcrumbs={[
              { text: 'My Account', url: '/account' },
              { text: 'Order History', url: '/orders' },
              { text: 'Order Details', url: `/orders/${id}` },
            ]}
          />
          <h1 className="text-2xl font-bold">Order Details</h1>
          {order && <Order order={order} />}
        </main>
      </div>
    </Page>
  )
}
