export default [
  {
    id: 'F77F17BA', // invoiceId
    date: 'August 2, 2020', // orderedDt
    status: 'Pending', //
    shipment: {
      shippingAddress: {
        // shipment.shippingAddress
        firstName: 'Daniel', // firstName
        lastName: 'Lopez', // lastName
        street1: '2575 237th St', // street1
        street2: '', //
        city: 'Torrance', // city
        provinceId: 'CA', // provinceId
        postalCode: '90505-5216', // postalCode
        countryId: 'USA', // countryId
        dayPhone: '(555) 555-5555', // dayPhone
        email: 'dlopez@pipingrock.com',
      },
    },
    payment: {
      billingAddress: {
        // pmtMethods[0].billingAddress
        firstName: 'Daniel', // firstName
        lastName: 'Lopez', // lastName
        street1: '2575 237th St', // street1
        street2: '', //
        city: 'Torrance', // city
        provinceId: 'CA', // provinceId
        postalCode: '90505-5216', // postalCode
        countryId: 'USA', // countryId
        dayPhone: '(555) 555-5555', // dayPhone
        email: 'dlopez@pipingrock.com', //
      },
      creditCard: {
        // pmtMethods[0].creditCard
        cardType: 'VISA', //
        last4: '1111', //
      },
    },
    summary: {
      subtotal: 64.47, // subAmt
      tax: 6.12, // taxAmt
      shipping: 5.95, // shipment.shippingInfo.shipFee
      total: 76.54, // totAmt
    },
    items: [
      // lines[0]
      {
        sku: '99-114', // productId
        name: 'CoQ10 30 mg 60 Softgels', // name
        qty: 1, // qtyOrdered
        price: 5.49, // price
        totalPrice: 5.49, // totPrice
        img: 'https://www.nutritionexpress.com/images/products/99/99-114-S75.gif', //
      },
      {
        sku: '99-614',
        name: 'Glucosamine Chondroitin Sulfate 240 Capsules',
        qty: 1,
        price: 38.99,
        totalPrice: 38.99,
        img: 'https://www.nutritionexpress.com/images/products/99/99-614-S75.gif',
      },
      {
        sku: '99-612',
        name: 'Glucosamine Chondroitin Sulfate 120 Capsules',
        qty: 1,
        price: 19.99,
        totalPrice: 19.99,
        img: 'https://www.nutritionexpress.com/images/products/99/99-612-S75.gif',
      },
    ],
  },
  {
    id: 'F77G6E5A',
    date: 'April 1, 2019',
    status: 'Shipped',
    shipment: {
      shippingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
    },
    payment: {
      billingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
      creditCard: { cardType: 'VISA', last4: '1111' },
    },
    summary: {
      subtotal: 5.49,
      tax: 0.52,
      shipping: 5.95,
      total: 11.96,
    },
    items: [
      {
        sku: '99-114',
        name: 'CoQ10 30 mg 60 Softgels',
        qty: 1,
        price: 5.49,
        totalPrice: 5.49,
        img: 'https://www.nutritionexpress.com/images/products/99/99-114-S75.gif',
      },
    ],
  },
  {
    id: 'F77FA4KC',
    date: 'November 9, 2018',
    status: 'Shipped',
    shipment: {
      shippingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
    },
    payment: {
      billingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
      creditCard: { cardType: 'VISA', last4: '1111' },
    },
    summary: {
      subtotal: 44.48,
      tax: 4.23,
      shipping: 5.95,
      total: 54.66,
    },
    items: [
      {
        sku: '99-114',
        name: 'CoQ10 30 mg 60 Softgels',
        qty: 1,
        price: 5.49,
        totalPrice: 5.49,
        img: 'https://www.nutritionexpress.com/images/products/99/99-114-S75.gif',
      },
      {
        sku: '99-614',
        name: 'Glucosamine Chondroitin Sulfate 240 Capsules',
        qty: 1,
        price: 38.99,
        totalPrice: 38.99,
        img: 'https://www.nutritionexpress.com/images/products/99/99-614-S75.gif',
      },
    ],
  },
  {
    id: 'F77G6E5Z',
    date: 'October 24, 2018',
    status: 'Cancelled',
    shipment: {
      shippingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
    },
    payment: {
      billingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
      creditCard: { cardType: 'VISA', last4: '1111' },
    },
    summary: {
      subtotal: 5.49,
      tax: 0.52,
      shipping: 5.95,
      total: 11.96,
    },
    items: [
      {
        sku: '99-114',
        name: 'CoQ10 30 mg 60 Softgels',
        qty: 1,
        price: 5.49,
        totalPrice: 5.49,
        img: 'https://www.nutritionexpress.com/images/products/99/99-114-S75.gif',
      },
    ],
  },
  {
    id: 'F77FA4KB',
    date: 'June 19, 2017',
    status: 'Cancelled',
    shipment: {
      shippingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
    },
    payment: {
      billingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
      creditCard: { cardType: 'VISA', last4: '1111' },
    },
    summary: {
      subtotal: 44.48,
      tax: 4.23,
      shipping: 5.95,
      total: 54.66,
    },
    items: [
      {
        sku: '99-114',
        name: 'CoQ10 30 mg 60 Softgels',
        qty: 1,
        price: 5.49,
        totalPrice: 5.49,
        img: 'https://www.nutritionexpress.com/images/products/99/99-114-S75.gif',
      },
      {
        sku: '99-614',
        name: 'Glucosamine Chondroitin Sulfate 240 Capsules',
        qty: 1,
        price: 38.99,
        totalPrice: 38.99,
        img: 'https://www.nutritionexpress.com/images/products/99/99-614-S75.gif',
      },
    ],
  },
  {
    id: 'F77FA4KD',
    date: 'May 9, 2017',
    status: 'Shipped',
    shipment: {
      shippingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
    },
    payment: {
      billingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
      creditCard: { cardType: 'VISA', last4: '1111' },
    },
    summary: {
      subtotal: 44.48,
      tax: 4.23,
      shipping: 5.95,
      total: 54.66,
    },
    items: [
      {
        sku: '99-114',
        name: 'CoQ10 30 mg 60 Softgels',
        qty: 1,
        price: 5.49,
        totalPrice: 5.49,
        img: 'https://www.nutritionexpress.com/images/products/99/99-114-S75.gif',
      },
      {
        sku: '99-614',
        name: 'Glucosamine Chondroitin Sulfate 240 Capsules',
        qty: 1,
        price: 38.99,
        totalPrice: 38.99,
        img: 'https://www.nutritionexpress.com/images/products/99/99-614-S75.gif',
      },
    ],
  },
  {
    id: 'F77F17BL',
    date: 'November 15, 2016',
    status: 'Cancelled',
    shipment: {
      shippingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
    },
    payment: {
      billingAddress: {
        firstName: 'Daniel',
        lastName: 'Lopez',
        street1: '2575 237th St',
        street2: '',
        city: 'Torrance',
        provinceId: 'CA',
        postalCode: '90505-5216',
        countryId: 'USA',
        dayPhone: '(555) 555-5555',
        email: 'dlopez@pipingrock.com',
      },
      creditCard: { cardType: 'VISA', last4: '1111' },
    },
    summary: {
      subtotal: 64.47,
      tax: 6.12,
      shipping: 5.95,
      total: 76.54,
    },
    items: [
      {
        sku: '99-114',
        name: 'CoQ10 30 mg 60 Softgels',
        qty: 1,
        price: 5.49,
        totalPrice: 5.49,
        img: 'https://www.nutritionexpress.com/images/products/99/99-114-S75.gif',
      },
      {
        sku: '99-614',
        name: 'Glucosamine Chondroitin Sulfate 240 Capsules',
        qty: 1,
        price: 38.99,
        totalPrice: 38.99,
        img: 'https://www.nutritionexpress.com/images/products/99/99-614-S75.gif',
      },
      {
        sku: '99-612',
        name: 'Glucosamine Chondroitin Sulfate 120 Capsules',
        qty: 1,
        price: 19.99,
        totalPrice: 19.99,
        img: 'https://www.nutritionexpress.com/images/products/99/99-612-S75.gif',
      },
    ],
  },
]
