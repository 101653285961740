import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import Page from '../components/page'
import Breadcrumbs from '../components/breadcrumbs'
import Orders from '../components/orders'
import LeftNav from '../components/accountLeftNav'
import fetch from '../lib/fetchJson'
import convertOrder from '../lib/convertOrder'
import testOrders from '../data/orders'

const customerIds = ['00819790', '00860469', '00904568', '00621987']

export default () => {
  const { pathname } = useLocation()
  const [customerId, setCustomerId] = useState(customerIds[0])
  const { data } = fetch(
    customerId
      ? `https://hist-api.systemne.com/customers/${customerId}/invoices`
      : // ? `https://kkwl4ahiu6.execute-api.us-west-2.amazonaws.com/dev/customers/${customerId}/invoices`
        null
  )

  const orders = customerId ? data?.map(order => convertOrder(order)) : testOrders
  return (
    <Page title="My Account - Orders">
      <div className="container flex flex-wrap px-2 mx-auto md:space-x-4 nex:max-w-5xl md:px-0">
        <aside className="w-full md:w-auto">
          <LeftNav current={pathname} />
        </aside>
        <main className="flex-grow space-y-2">
          <Breadcrumbs
            className="hidden md:flex"
            current={pathname}
            breadcrumbs={[
              { text: 'My Account', url: '/account' },
              { text: 'Order History', url: '/orders' },
            ]}
          />
          <div className="flex flex-wrap">
            <h1 className="flex-grow w-full text-2xl font-bold md:w-auto">Order History</h1>
            <select
              className="w-full text-center form-select md:w-auto"
              onChange={e => setCustomerId(e.target.value)}
              value={customerId}
            >
              <option>Select Customer ID</option>
              {customerIds.map(cId => (
                <option key={cId} value={cId}>
                  {cId}
                </option>
              ))}
            </select>
          </div>
          {orders?.length > 0 && <Orders orders={orders} />}
        </main>
      </div>
    </Page>
  )
}
