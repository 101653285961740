import React from 'react'
import { useLocation } from 'react-router-dom'

import Page from '../components/page'
import LeftNav from '../components/accountLeftNav'

export default () => {
  const { pathname } = useLocation()
  return (
    <Page title="My Account">
      <div className="container flex flex-wrap flex-grow px-2 mx-auto md:space-x-4 md:px-4 nex:max-w-5xl">
        <aside className="w-full md:w-auto">
          <LeftNav current={pathname} />
        </aside>
        <main className="flex-grow space-y-2">
          <h1 className="text-2xl font-bold">My Account</h1>
        </main>
      </div>
    </Page>
  )
}
