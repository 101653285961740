import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { HelmetProvider } from 'react-helmet-async'

import 'reactjs-popup/dist/index.css'
import './index.css'

import Home from './pages/home'
import Account from './pages/account'
import Orders from './pages/orders'
import Order from './pages/order'
import ArticleIndex from './pages/articleIndex'
import ContactUs from './pages/contactUs'
import Policy from './pages/policy'
import ShowArticle from './pages/showArticle'
import Email from './pages/email'
import Maintenance from './pages/maintenance'
import notFound from './pages/notFound'
import IE11 from './pages/ie'
import ThemeContextProvider from './lib/theme-context'
import ScrollToTop from './lib/scrollToTop'

export default () => (
  <HelmetProvider>
    <ThemeContextProvider>
      <Router>
        <ScrollToTop />
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/index.html" component={Home} />
            <Route path="/https%3A%2F%2Fnex-site.systemne.com%2F" component={Home} />
            <Route path="/account" component={Account} />
            <Route path="/orders/:id" component={Order} />
            <Route path="/orders" component={Orders} />
            <Route
              path={['/ne-articles/showarticle.aspx', '/**/showarticle.aspx']}
              component={ShowArticle}
            />
            <Route path="/article+index" component={ArticleIndex} />
            <Route path="/policy/contactus" component={ContactUs} />
            <Route path="/policy/:id" component={Policy} />
            <Route path="/policy/:id" component={Policy} />
            <Route path="/email-signup-thanks" component={Email} />
            <Route path="/maintenance" component={Maintenance} />
            <Route path="/ie11" component={IE11} />
            <Route path="/ne-images/OrderForm.pdf" onEnter={() => window.location.reload()} />
            <Route component={notFound} />
          </Switch>
        </QueryParamProvider>
      </Router>
    </ThemeContextProvider>
  </HelmetProvider>
)
