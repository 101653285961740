import React from 'react'
import { Link } from 'react-router-dom'

const accountLeftNav = [
  {
    text: 'My Account',
    url: '/account',
  },
  {
    text: 'Order History',
    url: '/orders',
  },
  // {
  //   text: 'Address Book',
  //   url: '/',
  // },
  // {
  //   text: 'Email Preferences',
  //   url: '/',
  // },
  // {
  //   text: 'Customer Service',
  //   url: '/',
  // },
]

export default ({ current }) => (
  <div className="space-y-1 md:px-4 md:py-2 md:border md:border-gray-400">
    <ul className="flex space-x-3 md:space-x-0 md:block md:space-y-1">
      {accountLeftNav.map(({ text, url }) => (
        <li key={text}>
          {current === url ? (
            <span className="text-sm font-bold text-orange-600">{text}</span>
          ) : (
            <Link to={url} className="text-sm hover:text-blue-700">
              {text}
            </Link>
          )}
        </li>
      ))}
    </ul>
  </div>
)
