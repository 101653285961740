import React, { useState } from 'react'

import Reorder from './reorder'
import toPrice from '../lib/toPrice'

const Address = ({
  address: {
    firstName,
    lastName,
    street1,
    street2,
    city,
    provinceId,
    postalCode,
    countryId,
    dayPhone,
  },
}) => (
  <address className="not-italic">
    {firstName} {lastName}
    <br />
    {street1}
    <br />
    {street2}
    {street2 && <br />}
    {city}, {provinceId} {postalCode}
    <br />
    {countryId}
    <br />
    {dayPhone}
  </address>
)

const TEST_ALT_ITEM = {
  img: 'https://cms-data.systemne.com/products/PK/PK-484-S75.gif',
  name: 'TEST ALT ITEM',
  prItem: {
    prImage:
      'https://cdn2.pipingrock.com/images/product/thumbnail/senior-vision-care-complex-300-capsules-484.jpg',
    description: 'Senior Vision Care Complex',
    sku: 484,
    status: 'alt',
  },
  price: 23.29,
  qty: 1,
  sku: 'TEST-ALT',
  totalPrice: 23.29,
}
const TEST_DISCONTINUED_ITEM = {
  img: 'https://cms-data.systemne.com/products/PK/PK-484-S75.gif',
  name: 'TEST DISCONTINUED ITEM',
  prItem: null,
  price: 23.29,
  qty: 1,
  sku: 'TEST-DISCONTINUED',
  totalPrice: 23.29,
}

export default ({
  order: {
    id,
    date,
    status,
    shipment: { shippingAddress },
    payment: { billingAddress, creditCard },
    summary: { subtotal, tax, shipping, total },
    items: initialItems,
  },
}) => {
  const [showReorderPopup, setShowReorderPopup] = useState(false)
  const [items, setItems] = useState([...initialItems, TEST_ALT_ITEM, TEST_DISCONTINUED_ITEM])
  const [selectAll, setSelectAll] = useState(false)
  const updateItems = sku => {
    const idx = items.findIndex(i => i.sku === sku)
    const item = items[idx]
    item.checked = !item.checked
    const newItems = [...items]
    newItems[idx] = item
    setItems(newItems)
  }
  const selectAllItems = checked => {
    const newItems = items.map(i => ({ ...i, checked }))
    setItems(newItems)
  }
  const reorderItems = items.some(item => item.checked)
    ? items
    : items.map(item => ({
        ...item,
        checked: true,
      }))
  return (
    <div className="space-y-2 md:space-y-4">
      <Reorder
        open={showReorderPopup}
        close={() => setShowReorderPopup(false)}
        items={reorderItems}
      />
      <div className="space-y-2 md:space-y-0 md:space-x-4 md:flex">
        <div className="p-4 border border-gray-400 rounded md:w-3/4">
          <div className="flex pb-2 mb-2 border-b border-gray-400">
            <div className="flex-grow">Order # {id}</div>
            <div className="flex-grow">Order Date: {date}</div>
            <div className="flex-grow">
              Order Status:{' '}
              <span className={status === 'Cancelled' ? 'text-red-700 font-bold' : ''}>
                {status}
              </span>
            </div>
          </div>
          <div>
            <div className="text-lg font-bold">
              {status === 'Cancelled'
                ? 'Your order has been cancelled'
                : status === 'Shipped'
                ? 'Your order has shipped'
                : 'Your order is pending'}
            </div>
            <div className="flex flex-wrap">
              <div className="flex-grow w-1/2 mb-4 md:mb-0 md:w-auto">
                <div className="font-semibold">Shipping Address</div>
                <Address address={shippingAddress} />
              </div>
              <div className="flex-grow w-1/2 mb-4 md:mb-0 md:w-auto">
                <div className="font-semibold">Billing Address</div>
                <Address address={billingAddress} />
              </div>
              <div className="flex-grow">
                <div className="font-semibold">Payment Method</div>
                <div>
                  {creditCard.cardType} ****{creditCard.last4}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 space-y-2 text-sm bg-gray-200 border border-gray-400 rounded md:w-1/4">
          <div className="font-bold">Order Summary</div>
          <div className="flex">
            <span className="flex-grow">Subtotal:</span>
            <span>{toPrice(subtotal)}</span>
          </div>
          <div className="flex">
            <span className="flex-grow">Tax:</span>
            <span>{toPrice(tax)}</span>
          </div>
          <div className="flex">
            <span className="flex-grow">Shipping:</span>
            <span>{toPrice(shipping)}</span>
          </div>
          <div className="flex">
            <span className="flex-grow">Order Total:</span>
            <span>{toPrice(total)}</span>
          </div>
          <div className="flex">
            <button
              type="button"
              className="w-full py-2 font-bold rounded bg-brand-yellow hover:bg-yellow-500"
              onClick={() => {
                setShowReorderPopup(true)
              }}
            >
              Reorder
            </button>
          </div>
          <p className="text-xs font-semibold leading-tight">
            Click the Reorder button to add the items from your order to your cart.
          </p>
          <p className="text-xs font-semibold leading-tight">View current item price in cart.</p>
        </div>
      </div>

      <div className="w-full border border-gray-400 rounded">
        <div className="items-center p-2 font-bold bg-gray-200 md:flex">
          <div className="flex-grow">Ordered Items</div>
          <div className="space-x-2 font-semibold">
            <button
              type="button"
              disabled={items.filter(i => i.checked).length === 0}
              className="px-2 py-1 font-semibold rounded bg-brand-yellow hover:bg-yellow-500 disabled:opacity-25 disabled:pointer-events-none"
              onClick={() => setShowReorderPopup(true)}
            >
              Add Selected to Cart
            </button>
            <label>
              Select All
              <input
                type="checkbox"
                className="ml-1"
                checked={selectAll || items.every(i => i.checked)}
                onChange={async () => {
                  selectAllItems(!selectAll)
                  setSelectAll(!selectAll)
                }}
              />
            </label>
          </div>
        </div>
        <div className="py-3 space-y-3">
          <div className="flex font-bold">
            <div className="flex justify-center w-1/6"> </div>
            <div className="w-1/3">Name & Description</div>
            <div className="flex justify-center w-1/6">Qty</div>
            <div className="flex justify-center w-1/6">Item Price</div>
            <div className="flex justify-center w-1/6">Total</div>
            <div className="flex justify-center w-1/6"> </div>
          </div>
          <ul className="space-y-3">
            {items.map(({ sku, name, qty, price, totalPrice, img, checked }) => (
              <li key={sku} className="flex">
                <div className="flex items-center justify-center w-1/6">
                  <img
                    src={img}
                    alt={name}
                    onError={e => {
                      e.target.src = 'https://cms-data.systemne.com/products/default-s75.gif'
                    }}
                  />
                </div>
                <div className="w-1/3">
                  <div className="text-sm">{sku}</div>
                  <div>{name}</div>
                </div>
                <div className="flex items-center justify-center w-1/6">{qty}</div>
                <div className="flex items-center justify-center w-1/6">{price}</div>
                <div className="flex items-center justify-center w-1/6">{totalPrice}</div>
                <div className="flex items-center justify-center w-1/6">
                  <input
                    type="checkbox"
                    checked={checked || false}
                    onChange={() => {
                      if (checked) setSelectAll(false)
                      updateItems(sku)
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
