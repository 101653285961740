import { useCartSummary } from './api'

const useUser = () => {
  const { data } = useCartSummary()
  const itemCount = data ? data.item_count : 0
  const user = data ? data.user_info : {}
  const { first_name: firstName, email } = user
  const loggedIn = firstName !== undefined
  return {
    loading: data === undefined,
    itemCount,
    firstName,
    loggedIn,
    email,
  }
}

export default useUser
