import React, { useState, useEffect } from 'react'
import Popup from 'reactjs-popup'

export default ({ open, close, items: initialItems }) => {
  const [items, setItems] = useState(initialItems)
  useEffect(() => {
    setItems(initialItems)
  }, [initialItems])
  const updateItems = sku => {
    const idx = items.findIndex(i => i.sku === sku)
    const item = items[idx]
    item.checked = !item.checked
    const newItems = [...items]
    newItems[idx] = item
    setItems(newItems)
  }
  const addToCart = itemsToCart => {
    alert(
      `Added to cart:\n${itemsToCart.reduce((prev, item) => {
        prev += `${item.prItem.sku}\n`
        return prev
      }, '')}`
    )
  }
  return (
    <Popup open={open} closeOnDocumentClick onClose={close}>
      <div className="modal">
        <button className="absolute top-0 right-0" type="button" onClick={close}>
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="flex p-2 font-bold bg-gray-200">
          <div className="flex justify-center w-1/2">What you ordered</div>
          <div className="flex justify-center w-1/2">What will be added to your cart</div>
        </div>
        <ul className="py-3 space-y-3">
          {items
            .filter(({ prItem }) => prItem && prItem.status === 'exact')
            .map(({ sku, name, img, checked, prItem }) => (
              <li key={sku} className={`flex ${!prItem ? 'opacity-50' : ''}`}>
                <div className="flex items-center justify-center w-1/6">
                  <img
                    src={img}
                    alt={name}
                    onError={e => {
                      e.target.src = 'https://cms-data.systemne.com/products/default-s75.gif'
                    }}
                  />
                </div>
                <div className="w-3/12">
                  <div className="text-sm">{sku}</div>
                  <div>{name}</div>
                </div>
                {prItem ? (
                  <>
                    <div className="flex items-center justify-center w-1/6">
                      <img
                        src={prItem.prImage}
                        alt={prItem.description}
                        style={{ width: '40px' }}
                      />
                    </div>
                    <div className="w-3/12">
                      <div className="text-sm">{prItem.sku}</div>
                      <div>{prItem.description}</div>
                    </div>
                    <div className="flex items-center justify-center w-1/6">
                      <input
                        type="checkbox"
                        checked={checked || false}
                        onChange={() => {
                          updateItems(sku)
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex items-center justify-center w-1/6"> </div>
                    <div className="flex items-center w-3/12">Item has been discontinued</div>
                    <div className="flex items-center justify-center w-1/6">
                      <input
                        className="cursor-not-allowed"
                        type="checkbox"
                        checked={false}
                        readOnly
                      />
                    </div>
                  </>
                )}
              </li>
            ))}
        </ul>
        {items.some(({ prItem }) => !prItem || prItem.status === 'alt') && (
          <>
            <div className="flex p-2 font-bold bg-gray-200">
              <div className="flex justify-center w-1/2">Discontinued Item</div>
              <div className="flex justify-center w-1/2">Suggested Item</div>
            </div>
            <ul className="py-3 space-y-3">
              {items
                .filter(({ prItem }) => !prItem || prItem.status === 'alt')
                .map(({ sku, name, img, checked, prItem }) => (
                  <li key={sku} className="flex">
                    <div className="flex items-center justify-center w-1/6 opacity-50">
                      <img
                        src={img}
                        alt={name}
                        onError={e => {
                          e.target.src = 'https://cms-data.systemne.com/products/default-s75.gif'
                        }}
                      />
                    </div>
                    <div className="w-3/12 opacity-50">
                      <div className="text-sm">{sku}</div>
                      <div>{name}</div>
                    </div>
                    {prItem ? (
                      <>
                        <div className="flex items-center justify-center w-1/6">
                          <img
                            src={prItem.prImage}
                            alt={prItem.description}
                            style={{ width: '40px' }}
                          />
                        </div>
                        <div className="w-3/12">
                          <div className="text-sm">{prItem.sku}</div>
                          <div>{prItem.description}</div>
                        </div>
                        <div className="flex items-center justify-center w-1/6">
                          <input
                            type="checkbox"
                            checked={checked || false}
                            onChange={() => {
                              updateItems(sku)
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center justify-center w-1/6"> </div>
                        <div className="flex items-center w-3/12 opacity-50">No suggested item</div>
                        <div className="flex items-center justify-center w-1/6 opacity-50">
                          <input
                            className="cursor-not-allowed"
                            type="checkbox"
                            checked={false}
                            readOnly
                          />
                        </div>
                      </>
                    )}
                  </li>
                ))}
            </ul>
          </>
        )}
        <div className="flex justify-end pb-4 pr-4">
          <button
            type="button"
            disabled={items.filter(i => i.checked).length === 0}
            className="px-2 py-1 font-semibold rounded bg-brand-yellow hover:bg-yellow-500 disabled:opacity-25 disabled:pointer-events-none"
            onClick={() => {
              addToCart(items.filter(i => i.checked && i.prItem))
            }}
          >
            Add Selected to Cart
          </button>
        </div>
      </div>
    </Popup>
  )
}
