/**
 * Scrolls the page to the element received on the props as id, the id of the element needs to be on the URL.
 *
 * @param props = id
 * @returns {null}
 */
export default function ScrollTo(props) {
    setTimeout(function () {
        if (window.location.href.indexOf(props.id) > -1) {
            const element = document.querySelector(props.id);
                if (element != null) {
                    if (window.innerWidth < 1024) {
                        window.scrollTo({top: element.offsetTop - document.getElementsByTagName("header")[0].offsetHeight-25});
                    } else {
                        element.scrollIntoView();
                    }
                }
            }
    }, 100);

    return null
}