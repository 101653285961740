import article0061 from './0061.json'
import article0260 from './0260.json'
import article0787 from './0787.json'

export default [
  {
    id: 61,
    text: 'When to take creatine and how much to take',
    url: '/ne-articles/showarticle.aspx?id=61',
    img: '/ne-images/Articles/Thumbnails/61.gif',
    article: article0061,
  },
  {
    id: 260,
    text: 'Creatine: Should you cycle 3 months on, 1 month off?',
    url: '/ne-articles/showarticle.aspx?id=260',
    img: '/ne-images/Articles/Thumbnails/260.gif',
    article: article0260,
  },
  {
    id: 787,
    text: 'Whey vs. casein protein',
    url: '/ne-articles/showarticle.aspx?id=787',
    img: '/ne-images/Articles/Thumbnails/787.gif',
    article: article0787,
  },
]
