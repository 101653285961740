import moment from 'moment'

const hourPeriods = [9, 7, 5, 8, 4]
const startDate = '2016-10-18 10:54:00'

// from http://stage10-www.testpipingrock.com/website/js/crazyDealsTimer.js
function calculateCrazyDealsDuration() {
  const // milliseconds to hours factor
    millisecondsHours = 1000 * 60 * 60
  // first period by default
  let crazyDealPeriod = hourPeriods[0]
  // start date in local time
  let crazyDealsStart = moment.utc(startDate).local()
  const currentTime = moment()
  // elapsed time from start date
  let timeFromStart = currentTime.isAfter(crazyDealsStart) ? currentTime.diff(crazyDealsStart) : 0
  // total time for a round of all periods in hours (all periods must be in hours)
  const totalHours = hourPeriods.reduce((a, b) => a + b, 0)
  // total time for a round of all periods in milliseconds
  const totalHoursTime = totalHours * millisecondsHours

  /**
   * calculate how many times does the total time of periods fit in the elapsed time from
   * start date to decrease that time so we can find which period should be currently running
   */
  if (timeFromStart >= totalHoursTime) {
    timeFromStart -=
      totalHours * Math.floor(timeFromStart / millisecondsHours / totalHours) * millisecondsHours
  }

  /**
   * look for the corresponding period that should be currently running depending on the
   * elapsed time from the start date
   */
  hourPeriods.forEach(hour => {
    if (timeFromStart >= hour * millisecondsHours) {
      timeFromStart -= hour * millisecondsHours
    } else {
      crazyDealPeriod = hour
      crazyDealsStart = moment(currentTime).subtract(timeFromStart / 1000, 'seconds')
      return false
    }
  })

  /**
   * calculate remaining time for the current period
   */
  const crazyDealsEnd = moment(crazyDealsStart).add(crazyDealPeriod, 'h')
  const endsIn = moment.duration(crazyDealsEnd.diff(currentTime), 'milliseconds')

  // setTimerValues(endsIn.hours(), endsIn.minutes(), endsIn.seconds())
  return { hours: endsIn.hours(), minutes: endsIn.minutes(), seconds: endsIn.seconds() }
}

export default calculateCrazyDealsDuration
