export const isGladlyInit = () => {
    if(!window.Gladly.show) {
        window.Gladly.init({
            appId: "pipingrock.com-nutritionexpress"
        }).then(function() {
            window.Gladly.show();
        }).catch(function(error) {
            // If anything goes wrong when Sidekick is being initialized, this gets called.
            console.log('error:', error)
        });
    } else {
        window.Gladly.show();
    }
}

export const gladlyContactForm = () => {
    var mail = 'mailto:customercare@nutritionexpress.com';
    var a = document.createElement('a');
    a.href = mail;
    a.click();
}

export const gladlyChat = () => {
    isGladlyInit();
}

export default gladlyContactForm;