import React, { useState } from 'react'
import { useCombobox } from 'downshift'

import { useSuggestions } from '../lib/api'

const searchUrl = search => `/search?k=${search}&os=&ls=1`

const Search = () => {
  const [search, setSearch] = useState('')
  const { data: suggestions } = useSuggestions(search)
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: suggestions || [],
    onInputValueChange: ({ inputValue }) => {
      setSearch(inputValue)
    },
    onSelectedItemChange: changes => {
      const { selectedItem, type } = changes
      if (type !== '__input_blur__' && type !== 7) window.location = searchUrl(selectedItem)
    },
  })
  return (
    <div className="relative w-full text-gray-700 lg:w-84">
      <div
        {...getComboboxProps()}
        className={`flex search-btn w-full overflow-hidden border lg:border-1 border-brand-blue nex:border-nex-blue hover:border-brand-orange focus-within:border-brand-orange ${
          suggestions?.length > 0 && isOpen ? 'rounded-b-none' : ''
        }`}
      >
        <input
          className="flex-grow px-2 py-2 border-transparent focus:outline-none search-input placeholder-black::placeholder"
          placeholder="Enter keyword or item number"
          value={search}
          onChange={e => setSearch(e.target.value)}
          {...getInputProps({
            onKeyDown: e => {
              if (e.key === 'Enter') {
                if (e.target.value.length > 0) {
                  window.location = searchUrl(e.target.value)
                } else {
                  window.location.reload()
                }
              }
            },
          })}
        />
        <button
          className="flex items-center px-2 py-1 font-bold text-gray-100 bg-brand-blue fs-15"
          type="button"
          onClick={() => {
            if (search.length > 0) {
              window.location = searchUrl(search)
            } else {
              window.location.reload()
            }
          }}
          data-category="Top Navigation"
          data-action="Button"
          data-label="Search button"
        >
          <span className="hidden mr-2 lg:block">Search</span>
          <img src="/ne-images/w-magnifying-glass11.png" alt="" />
        </button>
      </div>

      <ul
        {...getMenuProps()}
        className={`absolute z-50 w-full bg-gray-100 rounded border-brand-orange nex:border-nex-blue ${
          suggestions?.length > 0 && isOpen ? 'border-2 border-t-0 rounded-t-none' : ''
        }`}
      >
        {suggestions?.length > 0 &&
          isOpen &&
          suggestions.map((item, index) => (
            <li
              key={`${item}${index}`}
              className={`border-b border-gray-300 ${
                highlightedIndex === index ? 'bg-gray-300' : ''
              }`}
              {...getItemProps({ item, index })}
            >
              <a className="block px-2 py-1" href={searchUrl(item)}>
                {item}
              </a>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default Search
