const links = {
  contact: {
    text: 'Contact Us',
    url: '/policy/contactus',
    category: 'Footer Navigation',
    action: 'Link',
    label: 'Contact Us',
  },
  signin: {
    text: 'Sign In / Join',
    url: '/login',
    category: 'Footer Navigation',
    action: 'Link',
    label: 'Sign In Join',
    pr: true,
  },
  about: {
    text: 'About Us',
    url: '/policy/aboutus',
    category: 'Footer Navigation',
    action: 'Link',
    label: 'About Us',
  },
  nonGmo: {
    text: 'Non-GMO Pledge',
    url: '/policy/nongmopledge',
    category: 'Footer Navigation',
    action: 'Link',
    label: 'Non-GMO Pledge',
  },
  faq: {
    text: 'FAQ',
    url: '/policy/faqs',
    category: 'Footer Navigation',
    action: 'Link',
    label: 'FAQ',
  },
  shipping: {
    text: 'Shipping Policy',
    url: '/policy/shippingordering',
    category: 'Footer Navigation',
    action: 'Link',
    label: 'Shipping Policy',
  },
  return: {
    text: 'Return Policy',
    url: '/policy/guaranteereturns',
    category: 'Footer Navigation',
    action: 'Link',
    label: 'Return Policy',
  },
}

export const desktop = [
  {
    title: 'Customer Service',
    links: [links.contact, links.faq, links.signin],
  },
  {
    title: 'Orders & Policies',
    links: [links.shipping, links.return],
  },
  {
    title: 'Company',
    links: [links.about, links.nonGmo],
  },
  {
    title: 'Ways to Shop',
    links: [
      {
        text: 'Order by Phone 1-800-338-7979',
      },
      {
        text: 'Order Form (PDF)',
        url: '/ne-images/OrderForm.pdf',
        target: '_blank',
      },
    ],
  },
  {
    title: 'Contact Us',
    links: [
      {
        text: '1-800-754-8000',
      },
      {
        text: 'Sunday – Closed',
      },
      {
        text: 'Mon – Fri  - 9am – 9pm (EST)',
      },
      {
        text: 'Saturday – 10am-6pm (EST)',
      },
    ],
  },
]

export const mobile = [
  {
    title: 'Contact Us',
    links: [links.contact, links.signin],
  },
  {
    title: 'Information',
    links: [links.faq, links.shipping, links.return],
  },
  {
    title: 'Company',
    links: [links.about, links.nonGmo],
  },
]
