import React from 'react'
import { Helmet } from 'react-helmet-async'

export default () => {
  const title = "We'll be right back!"
  return (
    <div id="page" className="flex flex-col items-center min-h-screen">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="p-4 text-center md:p-0">
        <img src="/ne-images/ne_site_down_mobile.jpg" alt={title} className="md:hidden" />
        <img
          src="/ne-images/ne_site_down_desktop.jpg"
          alt={title}
          className="hidden md:block md:mt-8"
        />
        <hr className="my-4 border border-brand-blue" />
        <h1 className="text-3xl text-gray-900 md:text-5xl">{title}</h1>
        <p className="font-light text-gray-700 md:text-xl">
          We are currently upgrading our systems,
          <br className="hidden md:block" /> working hard as always to continue bringing you the
          best for less.
        </p>
        <hr className="my-4 border border-brand-blue" />
        <p className="font-light text-gray-700 md:text-xl">
          Order by Phone{' '}
          <a className="text-blue-600 hover:text-blue-500" href="tel:18003387979">
            1-800-338-7979
          </a>
          <br />
          Email -{' '}
          <a
            className="text-blue-600 hover:text-blue-500"
            href="mailto:CustomerCare@NutritionExpress.com"
          >
            CustomerCare@NutritionExpress.com
          </a>
        </p>
      </div>
    </div>
  )
}
