import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Search from './search'
import useUser from '../lib/useUser'

function openMenu(){
    const div = document.getElementById("root")
    const rect = div.getBoundingClientRect();
    const y = rect.top;

    document.getElementById("page").style.top = `${y}px`;
    document.getElementById("root").classList.add("prevent-scroll");
    document.getElementsByTagName("body")[0].classList.add("html-scroll");
}

const Logo = () => (
  <div className="self-center flex-grow lg:flex-grow-0 lg:pb-0">
    <Link
      to="/"
      data-category="Top Navigation"
      data-action="Click"
      data-label="Piping Rock Logo Image"
    >
      <img
        className="hidden mx-auto lg:h-auto lg:flex"
        src="/ne-images/topnav/NE-PR_new.png"
        alt="Nutrition Express by Piping Rock"
      />
      <img
        className="m-0 ml-1 mobile-logo lg:hidden"
        src="/ne-images/topnav/NE-PR-Mobile_new.gif"
        alt="Nutrition Express by Piping Rock"
      />
    </Link>
  </div>
)

const MenuButton = ({ setMenuOpen }) => (
  <button type="button" className="text-brand-blue lg:hidden" onClick={() => {setMenuOpen(true); openMenu()}}>
    <svg
      className="w-10 h-10"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
        clipRule="evenodd"
      />
    </svg>
  </button>
)

const Account = ({ user: { loading, firstName, loggedIn, email } }) => {
  const [open, setOpen] = useState(false)
  const links = loggedIn
    ? [
        {
          text: 'My Account',
          url: '/customer',
          category: 'Top Navigation',
          action: 'My Account Sub Menu Link',
          label: 'My Account',
        },
        {
          text: 'Logout',
          url: '/logout',
        },
      ]
    : [
        {
          text: 'Sign In',
          url: '/login',
          category: 'Top Navigation',
          action: 'Link',
          label: 'Sign In Join',
        },
        {
          text: 'Create an Account',
          url: '/register',
        },
      ]
  return (
    <>
      <a
        href={loggedIn ? '/customer' : '/login'}
        className="flex items-center lg:hidden text-brand-blue"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31px"
          height="31px"
          viewBox="0 0 44.196 44.196"
          fill="currentColor"
        >
          <path
            className="a_user"
            d="M165.058,63.871a21.6,21.6,0,0,0,0,43.2h.008a21.6,21.6,0,0,0,0-43.2ZM180.5,99.245c-1.1-2.526-4.542-4.139-7.034-5.233-1.021-.449-3.848-1.2-4.187-2.492-.533-2.034,1.493-3.582,2.393-5.182a15.548,15.548,0,0,0,1.893-5.88c.556-6.851-3.646-10.858-9.666-10.165-4.378.5-6.993,3.768-7.275,7.973a14.458,14.458,0,0,0,2.989,9.766c.739,1.015,1.514,1.668,1.4,2.89-.14,1.445-1.685,1.847-2.791,2.293a32.36,32.36,0,0,0-3.388,1.692c-2.1,1.161-4.384,2.539-5.192,4.365a20.707,20.707,0,1,1,30.862-.029Z"
            transform="translate(-142.96 -63.371)"
          />
        </svg>
      </a>
      <div className="user-links hidden text-sm lg:block">
        <div className={loading ? 'invisible' : 'visible'}>
          {loggedIn ? (
            <div className="text-xs truncate" style={{ maxWidth: '120px' }}>
              Hello, {firstName || email}
            </div>
          ) : (
            <a href="/login" className="hover:text-orange-600 fs-13">
              Sign In / Join
            </a>
          )}
        </div>
        <div
          className="relative flex-grow outline-none group"
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <a
            href="/customer"
            className="flex items-center justify-end w-full space-x-2 font-bold border-none outline-none group-hover:text-brand-orange"
            data-category="Top Navigation"
            data-action="Link"
            data-label="My Account"
          >
            <div>My Account</div>
            <img src="/ne-images/my_account_arrow.png" alt="my account arrow" />
          </a>
          {open && (
            <div
              className="absolute z-10 pt-2 font-bold"
              style={{
                maxWidth: '200px',
                minWidth: '170px',
              }}
            >
              <ul className="p-3 space-y-1 bg-gray-100 border-2 rounded-lg shadow outline-none border-brand-blue my-account-menu">
                {links.map(({ text, url, category, action, label }) => (
                  <li key={text}>
                    <a
                      className="block hover:underline hover:text-brand-blue"
                      href={url}
                      data-category={category}
                      data-action={action}
                      data-label={label}
                    >
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

function useQuantity(itemCount) {

    const [windowSize, setWindowSize] = useState("0");
    const [qty, setQty] = useState("0");

    useEffect(() => {
        function handleResize() {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        handleResize();
        if (windowSize < 1024) {
            if (itemCount > 99) {
                setQty("99+");
            } else {
                setQty(itemCount);
            }
        }
        else if (itemCount > 999) {
            setQty("999+");
        } else {
            setQty(itemCount);
        }

        return () => window.removeEventListener("resize", handleResize);
    });

    return qty;
}

const Cart = ({ user: { loading, itemCount } }) => (
  <div className="header-info flex items-center space-x-6 font-bold text-gray-700">
    <a
      className="flex items-center space-x-1 group"
      href="/cart"
      data-category="Header"
      data-action="Click"
      data-label="Cart Image"
    >
      <div
        className={`flex items-center justify-center text-center text-gray-100 rounded-full bg-brand-orange group-hover:bg-orange-400 w-8 h-8 ${
          itemCount > 999 ? 'lg:w-12 lg:h-12' :  ''
        } ${loading ? 'invisible' : 'visible'}`}
        style={
          itemCount <= 99
            ? {
                height: '23px',
                width: '23px',
                fontSize: '15px',
                lineHeight: '23px',
              }
            : {
                fontSize: '15px',
                lineHeight: '23px',
              }
        }
      >
          { useQuantity(itemCount) }
      </div>
      <img
        src="/ne-images/topnav/cart.png"
        alt="cart"
        className="cart-img"
        onMouseOver={e => {
          e.currentTarget.src = '/ne-images/topnav/cart_hover.png'
        }}
        onMouseOut={e => {
          e.currentTarget.src = '/ne-images/topnav/cart.png'
        }}
        onFocus={e => {
          e.currentTarget.src = '/ne-images/topnav/cart_hover.png'
        }}
        onBlur={e => {
          e.currentTarget.src = '/ne-images/topnav/cart.png'
        }}
      />
      <span className="hidden lg:block group-hover:underline">Cart</span>
    </a>
    <a
      href="/cart"
      className="checkout-button hidden px-2 py-1 font-bold text-gray-100 border border-gray-900 bg-brand-orange lg:flex hover:bg-orange-400"
      data-category="Top Navigation"
      data-action="Button"
      data-label="Checkout"
    >
      <span>Checkout</span>
      <svg
        className="w-6 h-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </a>
  </div>
)

export default ({ setMenuOpen }) => {
  const user = useUser()
  return (
    <div className="container flex flex-wrap p-2 pb-0 mx-auto space-y-2 max-w-7xl lg:py-4 lg:px-4 xl:px-0 lg:space-x-4 lg:flex-no-wrap lg:space-y-0 header-container">
      <div className="flex w-full space-x-4 lg:w-auto lg:space-x-0 lg:pb-0">
        <Logo />
        <div className="header-info flex items-center space-x-4 lg:hidden">
          <Account user={user} />
          <Cart user={user} />
        </div>
      </div>
      <div className="flex items-center flex-grow w-full lg:w-auto">
        <MenuButton setMenuOpen={setMenuOpen} />
        <Search />
      </div>
      <div className="items-center justify-end hidden space-x-6 text-gray-900 lg:flex">
        <Account user={user} />
        <Cart user={user} />
      </div>
    </div>
  )
}