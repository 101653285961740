import React from 'react'

import Page from '../components/page'
import LeftNav from '../components/leftNav'

export default () => (
  <Page>
    <div className="container flex justify-center mt-0 space-x-0 max-w-7xl lg:px-4 lg:space-x-4 md:mt-4 md:mx-auto xl:px-0">
      <LeftNav />
      <main className="w-full space-y-4 text-center md:w-5/6 text-brand-blue">
        <h2 className="text-2xl font-bold">Thanks for Signing Up!</h2>
        <h2 className="text-2xl font-bold">Your Inbox Just Got Healthier</h2>
        <h3 className="text-xl font-bold">
          Stay tuned, a whole lot of goodness is on its way (including crazy deals!)
        </h3>
        <a
          className="inline-block px-2 py-1 font-bold text-gray-100 border border-gray-900 bg-brand-orange hover:bg-orange-400"
          href="/"
        >
          Start shopping!
        </a>
        <p>
          Don't forget to check your spam box! Sometimes our awesome emails are too much to handle
          for your inbox.
        </p>
      </main>
    </div>
  </Page>
)
