import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

const convertOrder = ({
  invoiceId,
  invStatus,
  orderedDt,
  shipment: { shippingAddress, shippingInfo },
  pmtMethods,
  lines,
  subAmt,
  taxAmt,
  totAmt,
}) => ({
  id: invoiceId,
  date: format(parseISO(orderedDt), 'MMMM d, yyyy'),
  status: invStatus === 'S' ? 'Shipped' : invStatus === 'V' ? 'Cancelled' : 'Pending',
  shipment: {
    shippingAddress: {
      firstName: shippingAddress.firstName,
      lastName: shippingAddress.lastName,
      street1: shippingAddress.street1,
      street2: shippingAddress.street2,
      city: shippingAddress.city,
      provinceId: shippingAddress.provinceId,
      postalCode: shippingAddress.postalCode,
      countryId: shippingAddress.countryId,
      dayPhone: shippingAddress.dayPhone,
    },
  },
  payment: {
    billingAddress: {
      firstName: pmtMethods[0].billingAddress.firstName,
      lastName: pmtMethods[0].billingAddress.lastName,
      street1: pmtMethods[0].billingAddress.street1,
      street2: pmtMethods[0].billingAddress.street2,
      city: pmtMethods[0].billingAddress.city,
      provinceId: pmtMethods[0].billingAddress.provinceId,
      postalCode: pmtMethods[0].billingAddress.postalCode,
      countryId: pmtMethods[0].billingAddress.countryId,
      dayPhone: pmtMethods[0].billingAddress.dayPhone,
      email: pmtMethods[0].billingAddress.email,
    },
    creditCard: {
      // pmtMethods[0].creditCard
      cardType: pmtMethods[0].creditCard?.cardType,
      last4: pmtMethods[0].creditCard?.last4,
    },
  },
  summary: {
    subtotal: subAmt,
    tax: taxAmt,
    shipping: shippingInfo.shipFee,
    total: totAmt,
  },
  items: lines
    ? lines.map(({ productId, name, qtyOrdered, price, totPrice, prItem }) => ({
        sku: productId,
        name,
        qty: qtyOrdered,
        price,
        totalPrice: totPrice,
        img: `https://cms-data.systemne.com/products/${productId.substring(
          0,
          2
        )}/${productId}-S75.gif`,
        prItem,
      }))
    : [],
})

export default convertOrder
