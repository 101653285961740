import React from 'react'

import nav from '../data/nav'

export default () => (
  <div className="hidden lg:flex bg-brand-blue">
    <ul
      className="navbar container z-0 flex flex-wrap w-full mx-auto overflow-hidden divide-x divide-blue-500 max-w-7xl"
      style={{ height: '40px' }}
    >
      {nav.map(({ text, url, crazy, category, action, label }) => (
        <li
          key={text}
          className="flex items-center justify-center flex-grow font-bold leading-tight"
        >
          <a
            className={`w-full px-3 py-3 text-center text-gray-100 hover:text-brand-yellow hover:underline ${
              crazy ? 'text-yellow-400' : ''
            }`}
            href={url}
            data-category={category}
            data-action={action}
            data-label={label}
          >
            {text}
          </a>
        </li>
      ))}
    </ul>
  </div>
)
