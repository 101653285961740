import { host, query } from './host'

export default [
  {
    text: 'Crazy Deals',
    url: `${host}/sale-items${query}`,
    crazy: true,
    category: 'Top Navigation',
    action: 'Tab',
    label: 'Crazy Deals',
  },
  {
    text: 'Supplements',
    url: `${host}/supplements-mc${query}`,
    category: 'Top Navigation',
    action: 'Tab',
    label: 'Supplements',
  },
  {
    text: 'Vitamins',
    url: `${host}/vitamins-mc${query}`,
    category: 'Top Navigation',
    action: 'Tab',
    label: 'Vitamins',
  },
  {
    text: 'Herbs',
    url: `${host}/herbal-supplements-mc${query}`,
    category: 'Top Navigation',
    action: 'Tab',
    label: 'Herbs',
  },
  {
    text: 'Essential Oils',
    url: `${host}/essential-oils-mc${query}`,
    category: 'Top Navigation',
    action: 'Tab',
    label: 'Essential Oils',
  },
  {
    text: 'Beauty',
    url: `${host}/beauty-personal-care-mc${query}`,
    category: 'Top Navigation',
    action: 'Tab',
    label: 'Beauty',
  },
  {
    text: 'Sports',
    url: `${host}/sports-fitness-supplements-mc${query}`,
    category: 'Top Navigation',
    action: 'Tab',
    label: 'Sports',
  },
  {
    text: 'Weight Support',
    url: `${host}/weight-loss-support${query}`,
    category: 'Top Navigation',
    action: 'Tab',
    label: 'Weight Support',
  },
  {
    text: 'Nuts & Seeds',
    url: `${host}/nuts-seeds-mc${query}`,
    category: 'Top Navigation',
    action: 'Tab',
    label: 'Nuts & Seeds',
  },
  {
    text: 'Pet Products',
    url: `${host}/pet-products${query}`,
    category: 'Top Navigation',
    action: 'Tab',
    label: 'Pet Products',
  },
  {
    text: 'Liquid Extracts',
    url: `${host}/liquid-extracts${query}`,
    category: 'Top Navigation',
    action: 'Tab',
    label: 'Liquid Extracts',
  },
]
