import { useEffect, useState } from 'react'
import { CHANGE_SOURCE_API, BANNER_API } from '../config/api'

const getBanner = async source => {
  if (source) {
    await fetch(`${CHANGE_SOURCE_API}?prd=${source}`, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
  }
  const data = await fetch(BANNER_API, {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  }).then(res => res.json())
  return data
}

const useSource = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const [data, setData] = useState()
  let source = false

  if (urlParams.has('prd')) {
    source = urlParams.get('prd')
  }

  useEffect(() => {
    getBanner(source)
      .then()
      .then(res => {
        setData(res)
      })
  }, [source])

  return { data }
}

export default useSource
