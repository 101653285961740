import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'

import Header from './header'
import Footer from './footer'
import home from '../data/home'
import useUser from '../lib/useUser'

const { aside } = home

const DEFAULT_TITLE =
  'Nutrition Express by PipingRock Health Products - Shop for discount vitamins, supplements, bodybuilding products and more'

function closeMenu(){
    const numberPattern = /\d+/g;
    const scroll = document.getElementById("page").style.top.match(numberPattern)[0];
    document.getElementById("page").style.top = `0px`;
    document.getElementById("root").classList.remove("prevent-scroll");
    document.getElementsByTagName("body")[0].classList.remove("html-scroll");
    window.scrollTo(0, scroll);
}
const Page = ({ title = DEFAULT_TITLE, children }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { firstName, loggedIn, email } = useUser()

  return (
      <div>
          <aside
              className={`h-full flex fixed top-0 left-0 z-30 transition-all duration-300 ease-in-out transform lg:hidden w-full ${
                  menuOpen ? 'translate-x-0' : '-translate-x-full'
              }`}
          >
              <div className="w-2/3 bg-gray-100 overflow-auto">
                  <div className="flex justify-between p-2 bg-white">
                      <div className="font-bold truncate">
                          {loggedIn ? `Hello, ${firstName || email}` : 'Welcome'}
                      </div>
                      <div>
                          {loggedIn ? (
                              <a href="/login" className="underline hover:text-orange-600">
                                  Logout
                              </a>
                          ) : (
                              <>
                                  <a href="/login" className="underline hover:text-orange-600">
                                      Sign In
                                  </a>{' '}
                                  /{' '}
                                  <a href="/register" className="underline hover:text-orange-600">
                                      Join
                                  </a>
                              </>
                          )}
                      </div>
                  </div>
                  <ul className="divide-y divide-gray-300">
                      {aside
                          .filter(item => item.mobile)
                          .map(({ text, url, top }) => (
                              <li key={text} className="flex items-center p-2">
                                  <a
                                      href={url}
                                      className={`flex-grow hover:text-orange-600 ${
                                          top ? 'text-brand-blue font-semibold' : ''
                                      }`}
                                  >
                                      <span>{text}</span>
                                  </a>
                                  <svg
                                      className="w-6 h-6 text-gray-700"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                  >
                                      <path
                                          fillRule="evenodd"
                                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                          clipRule="evenodd"
                                      />
                                  </svg>
                              </li>
                          ))}
                  </ul>
              </div>
              <div
                  className="w-1/3 cursor-pointer focus:bg-transparent active:bg-transparent focus:outline-none"
                  onClick={() => {setMenuOpen(false); closeMenu()}}
              />
          </aside>
    <div id="page" className="flex flex-col min-h-screen md:space-y-4 font-body relative">
      <Helmet>
        <title>{title === DEFAULT_TITLE ? title : `${title} - ${DEFAULT_TITLE}`}</title>
      </Helmet>
      {menuOpen ? (
        <div className="fixed top-0 z-20 w-full h-full bg-gray-900 opacity-75 left-o" />
      ) : null}
      <Header setMenuOpen={setMenuOpen} />
      {children}
      <Footer />
    </div>
      </div>
  )
}

export default Page
