import React from 'react'

import HeaderNav from './headerNav'
import TopNav from './topNav'

export default ({ setMenuOpen }) => (
  <header className="sticky top-0 z-10 pb-2 bg-white border-b-2 border-gray-400 lg:static lg:top-auto lg:pb-0 lg:border-b-0">
    <HeaderNav setMenuOpen={setMenuOpen} />
    <TopNav />
  </header>
)
