import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { gladlyChat } from '../lib/openGladly'
import { desktop, mobile } from '../data/footer'
import { signupEmail } from '../lib/api'
import useSource from '../lib/useSource'

const Dropdown = () => (
  <div className="flex text-xs text-gray-700">
    {mobile.map(({ title, links }) => (
      <div key={title} className="relative flex-grow">
        <button
          className="flex items-center justify-center w-full outline-none"
          type="button"
          onClick={e => {
            e.preventDefault()
          }}
        >
          <span className="uppercase">{title}</span>{' '}
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <select
          className="absolute top-0 left-0 w-full p-1 outline-none opacity-0 bg-footer-bg"
          onChange={e => (window.location = e.target.value)}
          defaultValue="hidden"
        >
          <option className="hidden" value="hidden" />
          {links.map(({ text, url }) => (
            <option key={text} value={url}>
              {text}
            </option>
          ))}
        </select>
      </div>
    ))}
  </div>
)

const EmailSignup = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('Please enter your email address.')
  const validate = eml => eml !== '' && eml.indexOf('@') !== -1 && eml.indexOf(' ') === -1
  const submitEmail = async () => {
    const validEmail = validate(email)
    if (validEmail) {
      setError(false)
      await signupEmail(email).then((res) => {
        if (!res.success) {
          setErrorMessage(res.error)
          setError(true);
        }
      })
    } else setError(true)
  }
  return (
    <>
      <div className="flex border border-gray-700 md:w-96">
        <input
          type="email"
          className="flex-grow px-2 py-1 border-transparent focus:outline-none"
          placeholder="Enter email"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          onKeyDown={async e => {
            if (e.key === 'Enter') await submitEmail()
          }}
        />
        <button
          className="flex items-center px-2 py-1 space-x-2 font-bold text-gray-100 bg-brand-orange"
          type="submit"
          onClick={submitEmail}
          data-category="Footer Navigation"
          data-action="Click"
          data-label="Email Sign up"
        >
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {error && <div className="font-bold text-red-700">{errorMessage}</div>}
    </>
  )
}

export default () => {
  const { data } = useSource()
  const { footer_text } = data || {}
  return (
    <footer className="p-4 mt-2 border-t-2 bg-footer-bg md:mt-0 border-footer-border">
      <div className="container mx-auto max-w-7xl">
        <ul className="justify-between hidden mb-4 md:grid md:grid-cols-5">
          {desktop.map(({ title, links }) => (
            <li key={title} className="w-1/2 mb-4 md:w-auto md:mb-0">
              <div className="font-bold uppercase text-brand-blue">{title}</div>
              <ul>
                {links.map(link => (
                  <li key={link.text}>
                    {link.url ? (
                      link.pr ? (
                        <a
                          href={link.url}
                          className="text-sm text-gray-700 hover:text-blue-500"
                          target={link.target || '_self'}
                          data-category={link.category}
                          data-action={link.action}
                          data-label={link.label}
                        >
                          {link.text}
                        </a>
                      ) : (
                        <Link
                          to={link.url}
                          className="text-sm text-gray-700 hover:text-blue-500"
                          target={link.target || '_self'}
                          data-category={link.category}
                          data-action={link.action}
                          data-label={link.label}
                        >
                          {link.text}
                        </Link>
                      )
                    ) : (
                      <span className="text-sm text-gray-700">{link.text}</span>
                    )}
                  </li>
                ))}
              </ul>
              {title === 'Contact Us' && (
                <button type="button" onClick={gladlyChat} id="startZendesk" className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23px"
                    height="18px"
                    viewBox="0 0 44.234 34.818"
                    className="fill-current text-brand-blue"
                  >
                    <g transform="translate(-1258.5 -456.338)">
                      <g transform="translate(1270.06 456.338)">
                        <path
                          className="achat"
                          d="M1366.4,460.417a21.161,21.161,0,0,0-24.981,0,15.473,15.473,0,0,0-2.426,2.369l.034-.005.024.033a20.368,20.368,0,0,1,2.7-.18,18.387,18.387,0,0,1,11.358,3.737,12.4,12.4,0,0,1,5.062,9.787,11.477,11.477,0,0,1-1.039,4.774,12.138,12.138,0,0,1-.885,1.613,13.273,13.273,0,0,1-1.58,1.99l.021.03-.017.017a22.057,22.057,0,0,0,3.2-.337h0l5.621,4.714a.261.261,0,0,0,.428-.211l-.27-6.458a17.789,17.789,0,0,0,2.749-1.773,12.22,12.22,0,0,0,0-20.1Z"
                          transform="translate(-1338.989 -456.338)"
                        />
                      </g>
                      <g transform="translate(1258.5 464.735)">
                        <path
                          className="achat"
                          d="M1287.135,526.228a10.347,10.347,0,0,0-4.26-8.136,16.281,16.281,0,0,0-10.058-3.287,18.178,18.178,0,0,0-2.437.164,17.411,17.411,0,0,0-1.933.375,15.265,15.265,0,0,0-5.687,2.748,9.9,9.9,0,0,0,0,16.273,14.264,14.264,0,0,0,2.13,1.384l-.215,5.144a.321.321,0,0,0,.527.259l4.5-3.771a17.977,17.977,0,0,0,3.118.27,16.354,16.354,0,0,0,9.822-3.106c.08-.059.158-.12.236-.181a12.8,12.8,0,0,0,1.336-1.208,11.119,11.119,0,0,0,1.338-1.683,9.957,9.957,0,0,0,.734-1.337A9.4,9.4,0,0,0,1287.135,526.228Zm-4.934,6.757a12.308,12.308,0,0,1-1.757,1.218,15.359,15.359,0,0,1-7.627,1.952c-.53,0-1.054-.025-1.567-.075a15.936,15.936,0,0,1-1.784-.269.374.374,0,0,0-.318.079l-1.272,1.067-1.6,1.338.078-1.863.057-1.348a.377.377,0,0,0-.218-.356,13.527,13.527,0,0,1-1.237-.658c-3.02-1.818-4.965-4.656-4.965-7.84,0-4.077,3.192-7.589,7.743-9.114a15.124,15.124,0,0,1,1.768-.477,16.236,16.236,0,0,1,3.31-.336c7.071,0,12.823,4.453,12.823,9.927A8.828,8.828,0,0,1,1282.2,532.985Z"
                          transform="translate(-1258.5 -514.805)"
                        />
                      </g>
                    </g>
                  </svg>
                  <span className="text-sm text-gray-700 uppercase">Live Chat</span>
                </button>
              )}
            </li>
          ))}
        </ul>
        <div className="space-y-4">
          <div className="space-y-4 md:space-y-0 md:grid md:grid-cols-5">
            <div className="space-y-1 md:col-span-4">
              <div className="font-bold uppercase text-brand-blue">Email Sign Up</div>
              <p className="text-sm text-gray-700">
                Receive updates on new products and crazy deals!
              </p>
              <EmailSignup />
            </div>
            <div className="py-2 border-t border-b border-gray-400 md:hidden">
              <Dropdown />
            </div>
            <div className="flex-col items-center space-y-2 md:hidden text-brand-blue">
              <div className="text-2xl font-bold text-center">
                NEED HELP?
                <br />
                1-800-754-8000
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={gladlyChat}
                  className="flex items-center justify-center space-x-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23px"
                    height="18px"
                    viewBox="0 0 44.234 34.818"
                    className="fill-current"
                  >
                    <g transform="translate(-1258.5 -456.338)">
                      <g transform="translate(1270.06 456.338)">
                        <path
                          className="achat"
                          d="M1366.4,460.417a21.161,21.161,0,0,0-24.981,0,15.473,15.473,0,0,0-2.426,2.369l.034-.005.024.033a20.368,20.368,0,0,1,2.7-.18,18.387,18.387,0,0,1,11.358,3.737,12.4,12.4,0,0,1,5.062,9.787,11.477,11.477,0,0,1-1.039,4.774,12.138,12.138,0,0,1-.885,1.613,13.273,13.273,0,0,1-1.58,1.99l.021.03-.017.017a22.057,22.057,0,0,0,3.2-.337h0l5.621,4.714a.261.261,0,0,0,.428-.211l-.27-6.458a17.789,17.789,0,0,0,2.749-1.773,12.22,12.22,0,0,0,0-20.1Z"
                          transform="translate(-1338.989 -456.338)"
                        />
                      </g>
                      <g transform="translate(1258.5 464.735)">
                        <path
                          className="achat"
                          d="M1287.135,526.228a10.347,10.347,0,0,0-4.26-8.136,16.281,16.281,0,0,0-10.058-3.287,18.178,18.178,0,0,0-2.437.164,17.411,17.411,0,0,0-1.933.375,15.265,15.265,0,0,0-5.687,2.748,9.9,9.9,0,0,0,0,16.273,14.264,14.264,0,0,0,2.13,1.384l-.215,5.144a.321.321,0,0,0,.527.259l4.5-3.771a17.977,17.977,0,0,0,3.118.27,16.354,16.354,0,0,0,9.822-3.106c.08-.059.158-.12.236-.181a12.8,12.8,0,0,0,1.336-1.208,11.119,11.119,0,0,0,1.338-1.683,9.957,9.957,0,0,0,.734-1.337A9.4,9.4,0,0,0,1287.135,526.228Zm-4.934,6.757a12.308,12.308,0,0,1-1.757,1.218,15.359,15.359,0,0,1-7.627,1.952c-.53,0-1.054-.025-1.567-.075a15.936,15.936,0,0,1-1.784-.269.374.374,0,0,0-.318.079l-1.272,1.067-1.6,1.338.078-1.863.057-1.348a.377.377,0,0,0-.218-.356,13.527,13.527,0,0,1-1.237-.658c-3.02-1.818-4.965-4.656-4.965-7.84,0-4.077,3.192-7.589,7.743-9.114a15.124,15.124,0,0,1,1.768-.477,16.236,16.236,0,0,1,3.31-.336c7.071,0,12.823,4.453,12.823,9.927A8.828,8.828,0,0,1,1282.2,532.985Z"
                          transform="translate(-1258.5 -514.805)"
                        />
                      </g>
                    </g>
                  </svg>
                  <span>Live Chat</span>
                </button>
              </div>
            </div>
            <div className="flex justify-center md:col-span-1 md:justify-start">
              <div>
                <div className="hidden mb-2 font-bold uppercase md:block text-brand-blue">
                  Connect With Us
                </div>
                <div className="flex space-x-4 md:space-x-1">
                  <a
                    href="https://www.facebook.com/NutritionExpress"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34"
                      height="34.58"
                      viewBox="0 0 55.851 55.625"
                    >
                      <rect
                        className="afb"
                        width="55.851"
                        height="55.625"
                        rx="11"
                        transform="translate(0)"
                      />
                      <path
                        className="bfb"
                        d="M50.676,128.4V109.986h6.3l.943-7.29H50.676V98.041c0-2.111.588-3.55,3.627-3.55H58.18V87.969a52.091,52.091,0,0,0-5.648-.287c-5.589,0-9.415,3.4-9.415,9.637V102.7H36.8v7.29h6.321v18.591l3.227.058Z"
                        transform="translate(-19.562 -80.346)"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/nutritionexpress"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="w-34-p"
                      src="/ne-images/instagram-logo.svg"
                      alt="Instagram"
                    />
                  </a>
                  <a
                    href="https://www.pinterest.com/nutritionexpress"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34"
                      height="34.58"
                      viewBox="0 0 55.851 55.625"
                    >
                      <rect
                        className="aps"
                        width="55.851"
                        height="55.625"
                        rx="11"
                        transform="translate(0)"
                      />
                      <g transform="translate(7.366 7.336)">
                        <path
                          className="bps"
                          d="M165.041,87.682a20.463,20.463,0,0,0-8.253,39.231,17.882,17.882,0,0,1,.357-4.7c.395-1.662,2.645-11.157,2.645-11.157a7.837,7.837,0,0,1-.657-3.24c0-3.034,1.766-5.3,3.965-5.3,1.87,0,2.773,1.4,2.773,3.074,0,1.873-1.2,4.673-1.816,7.267A3.174,3.174,0,0,0,167.3,116.8c3.9,0,6.519-4.983,6.519-10.886,0-4.488-3.036-7.847-8.556-7.847a9.716,9.716,0,0,0-10.121,9.806,5.892,5.892,0,0,0,1.355,4.016.992.992,0,0,1,.3,1.141c-.1.377-.325,1.284-.419,1.643a.708.708,0,0,1-1.029.512c-2.873-1.168-4.211-4.3-4.211-7.823,0-5.817,4.926-12.791,14.695-12.791,7.849,0,13.014,5.657,13.014,11.73,0,8.032-4.484,14.033-11.093,14.033a5.9,5.9,0,0,1-5.023-2.552s-1.194,4.717-1.446,5.628a17.145,17.145,0,0,1-2.069,4.386,20.49,20.49,0,1,0,5.827-40.113Z"
                          transform="translate(-144.481 -87.682)"
                        />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr className="hidden border-gray-400 md:block" />
          {footer_text && <p className="text-center text-gray-700 text-2xs">{footer_text}</p>}
          <div className="flex justify-center text-xs divide-x divide-gray-700">
            <div className="px-3">
              <Link
                to="/policy/privacy"
                className="text-gray-700 hover:text-blue-500"
                data-category="Footer Navigation"
                data-action="Link"
                data-label="Privacy Policy"
              >
                Privacy Policy
              </Link>
            </div>
            <div className="px-3">
              <Link
                to="/policy/termsofuse"
                className="text-gray-700 hover:text-blue-500"
                data-category="Footer Navigation"
                data-action="Link"
                data-label="Terms of Use"
              >
                Terms of Use
              </Link>
            </div>
          </div>
          <p className="p-2 text-center text-gray-700 border border-gray-700 text-2xs md:p-1">
            **These statements have not been evaluated by the Food and Drug Administration. These
            products are not intended to diagnose, treat, cure or prevent any disease.{' '}
            <Link to="/policy/termsofuse#Disclaimer">Read More</Link>
          </p>
          <p className="text-center text-gray-700 text-2xs">
            All products sold on this site are for personal use and are not for resale.{' '}
            <Link to="/policy/termsofuse#Disclaimer">Read More</Link>
            <br />
            ©2000-2021 Nutrition Express. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
