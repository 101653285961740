import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useQueryParam } from 'use-query-params'

import Reorder from './reorder'
import toPrice from '../lib/toPrice'

const orderStatuses = [
  {
    text: 'All Orders',
    status: 'all',
  },
  {
    text: 'Shipped',
    status: 'shipped',
  },
  {
    text: 'Cancelled',
    status: 'cancelled',
  },
]

export default ({ orders: initialOrders }) => {
  const [status] = useQueryParam('status')
  const [search, setSearch] = useState('')
  const [showReorderPopup, setShowReorderPopup] = useState(false)
  const [reorderItems, setReorderItems] = useState([])
  const orders = initialOrders.filter(
    ({ status: orderStatus }) => !status || status === 'all' || status === orderStatus.toLowerCase()
  )
  const searchResults = orders.filter(
    ({ id, items }) => search === id || items.some(item => item.sku === search)
  )
  return (
    <div className="space-y-2">
      <Reorder
        open={showReorderPopup}
        close={() => setShowReorderPopup(false)}
        items={reorderItems}
      />
      <div className="flex flex-wrap">
        <ul className="flex items-center flex-grow w-full mb-2 space-x-4 md:mb-0 md:w-auto">
          {orderStatuses.map(({ text, status: orderStatus }) => (
            <li key={orderStatus}>
              {status === orderStatus || (!status && orderStatus === 'all') ? (
                <span className="text-orange-600">{text}</span>
              ) : (
                <Link
                  className="text-blue-700 hover:text-blue-500"
                  to={`/orders?status=${orderStatus}`}
                >
                  {text}
                </Link>
              )}
            </li>
          ))}
        </ul>
        <div className="flex w-full border-2 rounded border-brand-blue nex:border-nex-blue md:w-auto">
          <input
            className="w-full px-2 py-1 lg:w-64"
            type="text"
            placeholder="Search by number or product"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <button
            className="flex items-center px-2 py-1 space-x-2 font-bold text-gray-100 bg-brand-green nex:bg-nex-blue"
            type="button"
          >
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <ul className="space-y-4">
        {(searchResults.length > 0 ? searchResults : orders).map(
          ({ id, date, status: orderStatus, summary: { total }, items }) => (
            <li key={id} className="p-4 space-y-2 border border-gray-400 rounded-sm">
              <div className="space-y-2 md:space-y-2 md:flex">
                <div className="md:w-2/3">
                  <div>
                    {date} -{' '}
                    <span className={orderStatus === 'Cancelled' ? 'text-red-700 font-bold' : ''}>
                      {orderStatus}
                    </span>
                  </div>
                  <div>
                    <span className="mr-1">Order #</span>
                    <Link className="text-blue-700 hover:text-blue-500" to={`/orders/${id}`}>
                      {id}
                    </Link>{' '}
                    | Total {toPrice(total)}
                  </div>
                </div>
                <div className="flex space-x-4 md:space-x-0 md:space-y-2 md:flex-col md:w-1/3">
                  <Link
                    to={`/orders/${id}`}
                    type="button"
                    className="w-1/2 py-2 font-bold text-center text-gray-100 rounded md:w-auto bg-brand-green hover:bg-green-600"
                  >
                    Order Details
                  </Link>
                  <button
                    type="button"
                    className="w-1/2 py-2 font-bold text-gray-900 rounded bg-brand-yellow hover:bg-yellow-500 md:w-auto"
                    onClick={async () => {
                      await setReorderItems(
                        items.map(item => ({
                          ...item,
                          checked: true,
                        }))
                      )
                      setShowReorderPopup(true)
                    }}
                  >
                    Reorder
                  </button>
                </div>
              </div>
              <ul className="flex flex-wrap">
                {items.map(({ sku, img, name }) => (
                  <li key={sku}>
                    <img
                      src={img}
                      alt={name}
                      onError={e => {
                        e.target.src = 'https://cms-data.systemne.com/products/default-s75.gif'
                      }}
                    />
                  </li>
                ))}
              </ul>
            </li>
          )
        )}
      </ul>
    </div>
  )
}
