import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom'

import Page from '../components/page'
import Policy from '../components/policy'
import policies from '../data/policies'

export default () => {
  const { id } = useParams()
  const { policy } = policies.find(p => p.id === id)

    useEffect(() => {
        if (window.location.href.indexOf("#Disclaimer") > -1) {
            const element = document.querySelector("#Disclaimer");

            if (element != null) {
                if (window.innerWidth < 1024) {
                    window.scrollTo({top: element.offsetTop - document.getElementsByTagName("header")[0].offsetHeight-25});
                } else {
                    element.scrollIntoView();
                }
            }
        }
    });

  if (!policy)
    return (
      <Page>
        <div className="container flex flex-grow max-w-5xl mx-auto space-x-4">
          <main className="w-4/5" />
        </div>
      </Page>
    )
  return (
    <Page title={policy.title}>
      <div className="container flex justify-center max-w-5xl px-4 mt-0 space-x-0 lg:space-x-4 md:mt-4 md:mx-auto xl:px-0">
        <main>
          <Policy id={id} policy={policy} />
        </main>
      </div>
    </Page>
  )
}
