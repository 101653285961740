import React from 'react'
import { Link } from 'react-router-dom'

export default ({ breadcrumbs, current, className }) => (
  <ul className={`flex space-x-2 text-xs ${className || ''}`}>
    {breadcrumbs.map(({ text, url }) => (
      <li key={text}>
        {current && current === url ? (
          <span className="text-orange-600">{text}</span>
        ) : url ? (
          <Link to={url} className="text-blue-500">
            {text}
          </Link>
        ) : (
          <span>{text}</span>
        )}
      </li>
    ))}
  </ul>
)
