import React from 'react'
import home from '../data/home'

const { aside } = home

export default () => (
  <aside className="hidden pr-4 border-r lg:block lg:w-1/6 left-nav">
    <ul className="space-y-1">
      {aside
        .filter(({ desktop }) => desktop || desktop === undefined)
        .map(({ text, url, top, bold, sale, desktop, category, action, label }) => (
          <li key={text} className={desktop === false ? 'lg:hidden' : ''}>
            <a
              href={url}
              className={`hover:text-orange-600 ${top ? 'text-brand-blue left-nav-category' : ''} ${
                bold ? 'font-bold' : ''
              } ${sale ? 'text-orange-600' : ''}`}
              data-category={category}
              data-action={action}
              data-label={label}
            >
              {text}
            </a>
          </li>
        ))}
    </ul>
  </aside>
)
