import useSwr from 'swr'

import { SUGGESTIONS_API, CART_SUMMARY_API, BANNER_API } from '../config/api'

const fetcher = (...args) => fetch(...args).then(res => res.json())

export const signupEmail = async email => {
  let res;
  try {
    res = await fetcher('/email_ajax', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `task=email.submit_signup&email=${encodeURIComponent(email)}`,
    });
    if(res.success) {
      window.location.href = '/email-signup-thanks'
    }
  } catch (error) {
    console.log({ signupEmailError: error })
  }
  return res;
}

export const useSuggestions = search =>
  useSwr(search ? `${SUGGESTIONS_API}${search}` : null, url =>
    fetcher(url, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
  )

export const useCartSummary = () =>
  useSwr(CART_SUMMARY_API, url =>
    fetcher(url, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
  )

export const useArticle = id => useSwr(`/json/articles/${parseInt(id, 10)}.json`, fetcher)

export const useBanner = () =>
  useSwr(BANNER_API, url =>
    fetcher(url, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
  )
