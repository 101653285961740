import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import useSwr from 'swr'

import Page from '../components/page'
import fetchJson from '../lib/fetchJson'

const articleCategories = [8049, 6595, 8051, 17913, 8053, 11262, 8035, 18079, 8054, 8071]
// const articleCategories = [8071]

const fetchUrls = () => fetchJson('https://cms-data.systemne.com/categories/urls.json')

const fetcher = props => fetch(props).then(res => res.json())

const fetchCategory = async id => {
  try {
    const res = await fetcher(
      `https://cms-data.systemne.com/categories/body/${id.toString().padStart(5, '0')}.json`
    )
    return res
  } catch (e) {
    console.log({ error: e })
  }
}

const fetchArticle = id =>
  fetcher(`https://cms-data.systemne.com/articles/body/${id.toString().padStart(4, '0')}.json`)

const fetchArticleIndex = async () => {
  const categoryPromises = articleCategories.map(async id => {
    const category = await fetchCategory(id)
    const children = category.children.$values
    const childrenPromises = children.map(async childrenId => {
      const childCategory = await fetchCategory(childrenId)
      // const articlePromises = childCategory.articles?.$values.map(articleId =>
      //   fetchArticle(articleId)
      // )
      // const articles = await Promise.all(articlePromises)
      return {
        ...childCategory,
        // articles,
      }
    })
    const childrenCategories = await Promise.all(childrenPromises)
    return {
      id,
      ...category,
      children: childrenCategories,
    }
  })
  const fetchedCategories = await Promise.all(categoryPromises)
  return fetchedCategories
}

const ArticleIndex = () => {
  const { data: categories } = useSwr('/article+index', fetchArticleIndex)
  console.log({ categories })
  return (
    <Page title="Latest research and health news on vitamins, supplements, bodybuilding, sports nutrition and weight loss">
      <main className="container flex flex-col flex-grow max-w-5xl mx-auto space-y-3">
        <h1 className="text-lg font-semibold">Articles</h1>
        {categories && categories.length > 0 && (
          <ul className="space-y-2">
            {categories.map(({ id, url, title, children }) => (
              <li key={id} className="p-2 odd:bg-gray-200">
                <h2 className="text-lg font-semibold">
                  <Link className="text-blue-700 hover:text-blue-500" to={url}>
                    {title}
                  </Link>
                </h2>
                {children && children.length > 0 && (
                  <ul className="space-y-1">
                    {children.map(child =>
                      child.id ? (
                        <li className="p-2" key={child.id}>
                          <h3 className="font-semibold">
                            <Link className="text-blue-700 hover:text-blue-500" to={child.url}>
                              {child.title}
                            </Link>
                          </h3>
                          {/* {child.articles?.length > 0 && (
                          <ul className="space-y-1">
                            {child.articles.map(article => (
                              <li key={article.id}>
                                <Link
                                  className="text-blue-700 hover:text-blue-500"
                                  to={`/ne-articles/showarticle.aspx?id=${article.id}`}
                                >
                                  {article.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )} */}
                        </li>
                      ) : null
                    )}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </main>
    </Page>
  )
}

const fetchArticleCategory = async categoryId => {
  const category = await fetcher(
    `https://cms-data.systemne.com/categories/body/${categoryId.toString().padStart(5, '0')}.json`
  )
  const { children, articles } = category
  if (children) {
    const childrenIds = category.children.$values
    const childrenPromises = childrenIds.map(async childrenId => fetchCategory(childrenId))
    const childrenCategories = await Promise.all(childrenPromises)
    category.children = childrenCategories
  }
  if (articles) {
    const articlePromises = articles?.$values.map(articleId => fetchArticle(articleId))
    const categoryArticles = await Promise.all(articlePromises)
    category.articles = categoryArticles
  }
  return category
}

const ArticleCategory = ({ pathname }) => {
  const { data: urls } = fetchUrls()
  const { $values } = urls ? urls[pathname] : {}
  const categoryId = $values ? $values[$values.length - 1] : undefined
  const { data: category } = useSwr(categoryId, fetchArticleCategory)
  console.log({ category })
  if (!category) {
    return (
      <Page title="Latest research and health news on vitamins, supplements, bodybuilding, sports nutrition and weight loss">
        <main className="container flex flex-col flex-grow max-w-5xl mx-auto space-y-3" />
      </Page>
    )
  }
  const { title, children, articles } = category
  return (
    <Page title="Latest research and health news on vitamins, supplements, bodybuilding, sports nutrition and weight loss">
      <div className="container flex max-w-5xl mx-auto space-x-4">
        <aside className="w-1/5">
          <div className="p-3 space-y-3 text-sm border border-gray-400">
            <h1 className="font-bold">
              <Link to="/article+index" className="hover:text-blue-500">
                Article Index
              </Link>
            </h1>
            {children && (
              <ul className="space-y-1">
                {children.map(child => (
                  <li key={child.id}>
                    <Link to={child.url} className="hover:text-blue-500">
                      {child.title} ({child.articles?.$values.length})
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </aside>
        <main className="container flex flex-col flex-grow max-w-5xl mx-auto space-y-3">
          <h1 className="text-lg font-semibold">{title}</h1>
          {articles?.length > 0 && (
            <ul className="space-y-1">
              {articles.map(article => (
                <li key={article.id}>
                  <Link
                    className="text-blue-700 hover:text-blue-500"
                    to={`/ne-articles/showarticle.aspx?id=${article.id}`}
                  >
                    {article.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </main>
      </div>
    </Page>
  )
}

const Articles = () => {
  const { pathname } = useLocation()
  if (pathname === '/article+index') return <ArticleIndex />
  return <ArticleCategory pathname={pathname} />
}

export default Articles
