import React from 'react'

import Page from '../components/page'
import LeftNav from '../components/leftNav'

export default () => {
  return (
    <Page>
      <div className="container flex justify-center mt-0 space-x-0 max-w-7xl lg:px-4 lg:space-x-4 md:mt-4 md:mx-auto xl:px-0">
        <LeftNav />
        <main className="w-full px-4 pb-4 space-y-4 lg:pb-0 lg:px-0 lg:w-5/6">
          <h1 className="text-2xl font-bold">
            Thanks for visiting
            <br className="md:hidden" /> Nutrition Express.
          </h1>
          <p className="text-gray-800">
            For best results, please upgrade your Microsoft Web browser.
          </p>
          <div className="flex items-center space-x-4">
            <a
              href="https://www.microsoft.com/en-us/edge"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/ne-images/edge.png" alt="Microsoft Edge" className="" />
            </a>
            <a
              className="text-brand-blue hover:text-blue-500"
              href="https://www.microsoft.com/en-us/edge"
              target="_blank"
              rel="noopener noreferrer"
            >
              Upgrade to Microsoft Edge Browser.
            </a>
          </div>
          <p className="text-gray-800">
            Alternately, we suggest that you use one of these browsers instead:
          </p>
          <div className="flex items-center space-x-4">
            <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
              <img src="/ne-images/chrome.jpg" alt="Google Chrome" className="" />
            </a>
            <a
              className="text-brand-blue hover:text-blue-500"
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Upgrade to Google Chrome Browser.
            </a>
          </div>
          <div className="flex items-center space-x-4">
            <a
              href="https://www.mozilla.org/en-US/firefox/new/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/ne-images/firefox.jpg" alt="Mozilla Firefox" className="" />
            </a>
            <a
              className="text-brand-blue hover:text-blue-500"
              href="https://www.mozilla.org/en-US/firefox/new/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Upgrade to Mozilla Firefox Browser.
            </a>
          </div>
        </main>
      </div>
    </Page>
  )
}
