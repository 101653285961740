import { useState, useEffect } from 'react'

import calculateCrazyDealsDuration from './calculateCrazyDealsDuration'

const displayTime = value => (value < 10 ? `0${value}` : value)

const useTimer = () => {
  const initialTime = calculateCrazyDealsDuration()
  const [hours, setHours] = useState(initialTime.hours)
  const [minutes, setMinutes] = useState(initialTime.minutes)
  const [seconds, setSeconds] = useState(initialTime.seconds)
  useEffect(() => {
    const timer = setInterval(async () => {
      let s = seconds - 1
      let m = minutes
      let h = hours
      if (s < 0) {
        s = 59
        m -= 1
      }
      if (m < 0) {
        m = 59
        h -= 1
      }

      if (h < 0) {
        // next period must be calculated if current timer has reached the end
        const current = calculateCrazyDealsDuration()
        setHours(current.hours)
        setMinutes(current.minutes)
        setSeconds(current.seconds)
      } else {
        setHours(h)
        setMinutes(m)
        setSeconds(s)
      }
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [hours, minutes, seconds])
  return { hours: displayTime(hours), minutes: displayTime(minutes), seconds: displayTime(seconds) }
}

export default useTimer
