import React, { useState, useEffect } from 'react'

import useLocalStorage from './useLocalStorage'

export const ThemeContext = React.createContext()

export default function ThemeContextProvider({ children }) {
  const [storedTheme, setStoredTheme] = useLocalStorage('theme', 'NEX')
  const [theme, setTheme] = useState(storedTheme)
  useEffect(() => {
    setStoredTheme(theme)
  }, [theme, setStoredTheme])

  const defaultContext = {
    theme,
    setTheme,
  }

  return <ThemeContext.Provider value={defaultContext}>{children}</ThemeContext.Provider>
}
