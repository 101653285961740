import React from 'react'

import Page from '../components/page'
import { gladlyContactForm, gladlyChat } from '../lib/openGladly'

export default () => {
  const policy = { id: 'contactus', title: 'Contact Us' }
  return (
    <Page title={policy.title}>
      <div className="container flex justify-center max-w-5xl px-4 mt-0 space-x-0 lg:space-x-4 md:mt-4 md:mx-auto xl:px-0">
        <main>
          <article>
            <div className="text-sm article-content">
              <div className="pageTitle">Contact Us</div>
              <p>
                Your feedback is important to us. We will do our best to answer your questions or
                concerns in a timely manner. You can find answers to many questions in our FAQS
                section. For all other inquiries, please fill out the information below and click
                submit.
              </p>
              <h2 className="bold subhead18">By Live Chat:</h2>
              <div className="flex space-x-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23px"
                  height="18px"
                  viewBox="0 0 44.234 34.818"
                  className="fill-current text-brand-blue"
                >
                  <g transform="translate(-1258.5 -456.338)">
                    <g transform="translate(1270.06 456.338)">
                      <path
                        className="achat"
                        d="M1366.4,460.417a21.161,21.161,0,0,0-24.981,0,15.473,15.473,0,0,0-2.426,2.369l.034-.005.024.033a20.368,20.368,0,0,1,2.7-.18,18.387,18.387,0,0,1,11.358,3.737,12.4,12.4,0,0,1,5.062,9.787,11.477,11.477,0,0,1-1.039,4.774,12.138,12.138,0,0,1-.885,1.613,13.273,13.273,0,0,1-1.58,1.99l.021.03-.017.017a22.057,22.057,0,0,0,3.2-.337h0l5.621,4.714a.261.261,0,0,0,.428-.211l-.27-6.458a17.789,17.789,0,0,0,2.749-1.773,12.22,12.22,0,0,0,0-20.1Z"
                        transform="translate(-1338.989 -456.338)"
                      />
                    </g>
                    <g transform="translate(1258.5 464.735)">
                      <path
                        className="achat"
                        d="M1287.135,526.228a10.347,10.347,0,0,0-4.26-8.136,16.281,16.281,0,0,0-10.058-3.287,18.178,18.178,0,0,0-2.437.164,17.411,17.411,0,0,0-1.933.375,15.265,15.265,0,0,0-5.687,2.748,9.9,9.9,0,0,0,0,16.273,14.264,14.264,0,0,0,2.13,1.384l-.215,5.144a.321.321,0,0,0,.527.259l4.5-3.771a17.977,17.977,0,0,0,3.118.27,16.354,16.354,0,0,0,9.822-3.106c.08-.059.158-.12.236-.181a12.8,12.8,0,0,0,1.336-1.208,11.119,11.119,0,0,0,1.338-1.683,9.957,9.957,0,0,0,.734-1.337A9.4,9.4,0,0,0,1287.135,526.228Zm-4.934,6.757a12.308,12.308,0,0,1-1.757,1.218,15.359,15.359,0,0,1-7.627,1.952c-.53,0-1.054-.025-1.567-.075a15.936,15.936,0,0,1-1.784-.269.374.374,0,0,0-.318.079l-1.272,1.067-1.6,1.338.078-1.863.057-1.348a.377.377,0,0,0-.218-.356,13.527,13.527,0,0,1-1.237-.658c-3.02-1.818-4.965-4.656-4.965-7.84,0-4.077,3.192-7.589,7.743-9.114a15.124,15.124,0,0,1,1.768-.477,16.236,16.236,0,0,1,3.31-.336c7.071,0,12.823,4.453,12.823,9.927A8.828,8.828,0,0,1,1282.2,532.985Z"
                        transform="translate(-1258.5 -514.805)"
                      />
                    </g>
                  </g>
                </svg>
                <span className="text-sm text-gray-700">Live Chat</span>
              </div>
              <p>
                <button className="text-blue-700" type="button" onClick={gladlyChat}>
                  Click here to chat now.
                </button>{' '}
                Live Chat is available every day, 24 hours a day.
              </p>
              <h2 className="bold subhead18">By Email:</h2>
              <p>
                <button className="text-blue-700" type="button" onClick={gladlyContactForm}>
                  Click here to email us.
                </button>{' '}
                Our average email response time is 6 hours.
              </p>
              <h2 className="bold subhead18">By Phone:</h2>
              <p>
                Call us toll free during normal business hours:{' '}
                <a href="tel:8007548000" className="text-lg font-bold text-gray-700">
                  800-754-8000
                </a>{' '}
                <span className="text-blue-900">
                  Sunday – Closed, Mon – Fri - 9am – 9pm (EST), Saturday – 10am-6pm (EST).
                </span>{' '}
                After business hours, please leave a message and a representative will promptly call
                you back the next business day.
              </p>
              <h2 className="bold subhead18">By Fax:</h2>
              <p>
                Download our{' '}
                <a href="/ne-images/OrderForm.pdf" target="_blank">
                  order form
                </a>{' '}
                and fax your order to us:{' '}
                <a href="tel:8665884484" className="text-lg font-bold text-gray-700">
                  866-588-4484
                </a>{' '}
                (toll free). For faster shipment of your order, we suggest ordering by phone or our
                website.
              </p>
              <h2 className="bold subhead18">By Mail:</h2>
              <p>
                Nutrition Express
                <br />
                ATTN: Customer Service Department
                <br />
                2110 Smithtown Ave
                <br />
                Ronkonkoma, NY 11779
                <br />
              </p>
            </div>
          </article>
        </main>
      </div>
    </Page>
  )
}
