import useSwr from 'swr'

const fetcher = props => fetch(props).then(res => res.json())

export const fetchCategories = async categoryIds => {
  const categoryPromises = [8049, 6595, 8051, 17913, 8053, 11262, 8035, 18079, 8054, 8071].map(id =>
    fetcher(`https://cms-data.systemne.com/categories/body/${id.padStart(5, '0')}.json`)
  )

  const categories = await Promise.all(categoryPromises)
  console.log({ categories })
  return categories
}

export default (url, options = {}) => useSwr(url, fetcher, options)
